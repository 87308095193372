<template>
    <v-text-field
        v-model="inputedValue"
        :counter="20"
        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
        validate-on-blur
        :type="show_password ? 'text' : 'password'"
        @click:append="show_password = !show_password"
        :rules="rules"
    />
</template>

<script>

export default {
    name: 'PasswordTextField',
    props: {
        value: {
            type: String,
        },
        rules: Array,
    },
    data () {
        return {
            show_password: false,
        }
    },
    computed: {
        inputedValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        }
    },
    
}
</script>