<template>
  <v-container>
    <v-card
      flat
      class="pa-2"
      >

      <v-card-title class="title">
        <v-icon left>{{ $route.meta.icon }}</v-icon>
        {{ $route.meta.name }}
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            elevation="0"
            @click="openDialog('new',null)"
            v-if="selecttab==0">
            新規登録
          </v-btn>

          <v-dialog
            v-model="dialog"
            persistent
            scrollable
            max-width="600px">
            <v-form
              :action="qrurl" 
              method="POST"
              target="_blank"
              ref="form"
              v-model="valid"
              lazy-validation
              >
              <v-card 
                flat>
                
                <v-snackbar
                    v-model="copyqr"
                    :timeout="2000"
                    absolute
                    top
                  >
                  <span
                    v-if="!qrerror"
                    >
                    Copy
                    </span>
                    <span
                      class="error--text"
                      v-else
                      >
                      失敗しました
                    </span>
                </v-snackbar>  

                <v-card-text>
                  <v-row no-gutters>
                    <v-col 
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pt-2">
                    タイトル（レコーダー名）：
                    </v-col>
                    <v-col 
                      cols="12"
                      sm="12"
                      md="8"
                      lg="8">
                      <v-text-field
                          dense
                          v-model="editedItem.title"
                          :rules="rules.input"
                          required 
                          :disabled="dialogtype=='delete'? true:false"
                          ></v-text-field>
                      
                    </v-col>
                    
                    <v-col 
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="mt-2 mb-0">
                      
                      <v-row
                        align="center"
                        justify="center"
                      >
                        <v-card
                          flat
                          outlined
                          class="mt-2 mx-auto"
                          max-width="300"
                        >
                          <v-col>
                            <qriously id="qrcode" :value="qrurl" :size="130" class="ma-2" />
                          </v-col>
                          <v-overlay
                            :absolute="absolute"
                            :opacity="opacity"
                            :value="overlay"
                          >
                          <label class="text-h6 ext--white">Sample</label>
                          <!--
                            <v-btn
                              color="orange lighten-2"
                              @click="overlay = false"
                            >
                              Hide Overlay
                            </v-btn>
                          -->    
                          </v-overlay>
                                                              
                        </v-card>
                      </v-row>

                      <v-row 
                        no-gutters
                        v-if="dialogtype=='edit'">
                        <v-col cols="10"></v-col>
                        <v-menu 
                          offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon  
                              v-bind="attrs"
                              v-on="on">
                              mdi-dots-vertical
                            </v-icon>
                          </template>
                          <v-list 
                            dense>
                            
                            <v-list-item
                              @click="saveCanvas('png')">
                              <v-list-item-title>
                                <v-icon>mdi-download</v-icon> ダウンロード
                              </v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item
                              @click="onCopy"
                              >
                              <v-list-item-title>
                                <v-icon>mdi-content-copy</v-icon> URLコピー
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              @click="setQrcd"
                              >
                              <v-list-item-title>
                                <v-icon>mdi-refresh</v-icon> QRリフレッシュ
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              @click="formSubmit"
                              >
                              <v-list-item-title>
                                <v-icon>mdi-open-in-new</v-icon> 読込先へ
                              </v-list-item-title>
                            </v-list-item>

                          </v-list>
                        </v-menu>
                        
                      </v-row>

<!--
                      <v-row>
                        <v-col 
                          cols="12">
                          <v-text-field
                            ref="qrtarget"
                            v-model="qrurl"
                            outlined
                            dense
                            label="URL"
                            readonly
                            class="mt-2"
                            onfocus="this.select();"
                            @click="qrdisabled=false"
                            @blur="qrdisabled=true"
                            v-if="!overlay"
                          ></v-text-field>
                        </v-col>
                        
                          
                        <v-snackbar
                            v-model="copyqr"
                            :timeout="2000"
                            absolute
                            top
                          >
                          <span
                            v-if="!qrerror"
                            >
                            Copy
                            </span>
                            <span
                              class="error--text"
                              v-else
                              >
                              失敗しました
                            </span>
                        </v-snackbar>  
                      </v-row>
-->
                    </v-col>

                    <v-col 
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6">
                      
                      <v-checkbox
                        v-model="editedItem.is_latlng"
                        class="ml-2 py-0"
                        :disabled="dialogtype=='delete'? true:false"
                      >
                        <template v-slot:label>
                          <span class="pt-1">読込可エリアを設定する</span>
                        </template>
                      </v-checkbox>
                      <p 
                        class="ml-3"
                        v-if="editedItem.is_latlng && myLatLng.address!==null">
                        {{ myLatLng.address }}（半径{{ myLatLng.radius }}m）
                      </p>
                      <v-btn
                        text
                        color="primary"
                        @click="dialogGooglemap=true"
                        :disabled="dialogtype=='delete'? true:false"
                        v-if="editedItem.is_latlng">
                        Googleマップで設定
                      </v-btn>
                      
                      <v-row> 
                        <v-col 
                          cols="12">
                          <p class="mb-0 font-weight-bold">備考</p>
                          <v-textarea
                            v-model="editedItem.description"
                            auto-grow
                            outlined
                            :counter="200"
                            rows="5"
                            :disabled="dialogtype=='delete'? true:false"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-alert 
                          dense
                          text
                          :type="alert_type"
                          class="ma-2"
                          v-if="alert">
                            {{ alert_message }}
                        </v-alert>
                    </v-col>

                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    text
                    @click="closeDialog"
                  >
                    キャンセル
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    :disabled="!valid" 
                    color="primary" 
                    :loading="loading"
                    @click="saveItem"
                    v-if="dialogtype!=='delete'"
                    >
                    {{ dialogtext }}
                  </v-btn>
                  <v-btn
                    text
                    color="red" 
                    :loading="loading"
                    @click="deleteItem"
                    v-if="dialogtype=='delete'"
                    >
                    削 除
                  </v-btn>
                  
                </v-card-actions>
      
              </v-card>
            </v-form>
          </v-dialog>
          
          <v-dialog
            v-model="dialogGooglemap"
            persistent
            scrollable
            width="600px">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              >
              <v-card flat>
                <v-card-text>

                      <v-row>
                        <v-col 
                          cols="12">
                          <google-map 
                            :myLatLng="myLatLng" 
                            :zoom="17" 
                            :init="initPoint"
                            @get-distance="getDistance"
                            @set-latlng="setLatlng"/>
                          
                        </v-col>
                      </v-row>
                      <!--
                        <input type="hidden" name="_token" :value="csrf">
                      -->
                      <v-alert 
                        dense
                        text
                        :type="alert_type"
                        class="ma-2"
                        v-if="alert">
                          {{ alert_message }}
                      </v-alert>
                      
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    text
                    @click="dialogGooglemap=false"
                  >
                    キャンセル
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    :disabled="!valid" 
                    color="primary" 
                    :loading="loading"
                    @click="closeDialogGooglemap"
                  >
                    設 定
                  </v-btn>                  
                </v-card-actions>
      
              </v-card>
            </v-form>
          </v-dialog>
      </v-card-title>

      <v-row  no-gutters>
        <v-col 
          cols="12"
          sm="12"
          md="8"
          lg="8"
          class="mr-auto">
          
          <v-tabs
            v-model="selecttab"
            @change="selectTab()"
          >
            <v-tab
              v-for="(item,index) in tabs_activedtitle"
              :key="index"
            >
              {{ item }} 
              <v-badge
                color="green"
                :content="actived0"
                :value="actived0"
                v-if="index==2"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
          
        </v-col>
        <v-col 
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="pa-2"    
          v-if="selecttab!==1">
          <!--
          <a :href="`${appurl}spapitester`" target="_blank" class="mr-2">
          <v-icon small>mdi-open-in-new</v-icon>
          <span class="text-body-2">
            SP-API Tester(公開用)
          </span>
          </a>
          -->
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
            class="ma-2"
            ></v-text-field>

        </v-col>
      </v-row>
      <v-tabs-items 
        :touchless="true"
        v-model="selecttab">
        <v-tab-item 
          v-for="(item,index) in tabs_activedtitle"
          :key="index">
        
          <v-data-table
            :headers="headers0"
            :items="items0"
            :items-per-page="10"
            :page.sync="page0"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 30, 50, -1],
              'items-per-page-text':'ページ表示数'
            }"
            :loading="loading"
            :search="search"
            multi-sort
            class="elevation-0 mt-2"
            @update:page = "getTrecoders"
            v-if="selecttab==0"
          >

            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            

            <template v-if="items0.length==0" v-slot:no-data>
              データがありません
            </template>

            <template v-slot:[`item.is_latlng`]="{ item }">
                <span>{{ setIsLatlngString(item) }}</span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ setDtString(item.created_at) }}</span>
            </template>

            <template v-slot:[`item.updated_at`]="{ item }">
                <span>{{ setDtString(item.updated_at) }}</span>
            </template>

            
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu 
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    small 
                    class="ma-1"
                    v-bind="attrs"
                    v-on="on">
                    mdi-dots-vertical
                  </v-icon>
                </template>
                <v-list 
                  dense>
                  
                  <v-list-item
                    @click="openDialog('edit',item)">
                    <v-list-item-title>
                      <v-icon small>mdi-pencil</v-icon> 編集
                    </v-list-item-title>
                  </v-list-item>
                  
                  <v-list-item
                    :disabled="setDeleteItem(item)"
                    @click="openDialog('delete',item)"
                    >
                    <v-list-item-title>
                      <v-icon small>mdi-delete</v-icon> 削除
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </template>
            
<!--
            <template v-slot:[`item.comment`]="{ item }">
              <v-row  no-gutters>
                <v-col cols="auto">
                <span v-if="!item.editcomment">{{ item.comment }}</span>
                <v-text-field
                  v-model="item.comment"
                  single-line
                  :rules="rules.input"
                  required
                  dense
                  v-if="item.editcomment"
                  @change="updateComment(item)"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                <v-icon small @click="item.editcomment =! item.editcomment" v-if="!item.editcomment">mdi-pencil</v-icon>
                <v-icon small class="mt-1" @click="item.editcomment =! item.editcomment" v-if="item.editcomment && item.comment!=''">mdi-close</v-icon>
                </v-col>
              </v-row>
            </template>
-->
<!-- 
            <template v-slot:[`item.active`]="{ item }">
              <span :class="activecolors[item.active]">{{ setActiveTitle(item.active) }}</span>

            </template>

            <template v-slot:[`item.aws_access_key_id`]="{ item }">
                <span>{{ setAccessKeyId(item.aws_access_key_id) }}</span>
              
            </template>
            <template v-slot:[`item.lwa_client_id`]="{ item }">
                <span>{{ setAccessKeyId(item.lwa_client_id) }}</span>
              
            </template>

            <template v-slot:[`item.min_updated_at`]="{ item }">
                <span>{{ setDtString(item.min_updated_at,'min') }}</span>
              
            </template>
-->
          </v-data-table>
          
<!--
          <v-data-table
            :headers="headers1"
            :items="items1"
            :items-per-page="21"
            :page.sync="page1"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [21, 42, 63, -1],
              'items-per-page-text':'ページ表示数'
            }"
            :loading="loading"
            :search="search"
            multi-sort
            class="elevation-0 mt-2"
            @update:page = "getItemsLogs"
            v-if="selecttab==1"
          >

            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

            <template v-slot:[`header.updated_count`]="{ header }">
              <span class="primary--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.keepa_updated_count`]="{ header }">
              <span class="grey--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.new_items_count`]="{ header }">
              <span class="success--text">{{ header.text }}</span>
            </template>

            <template v-if="items1.length==0" v-slot:no-data>
              データがありません
            </template>

            <template v-slot:[`item.updated_date`]="{ item }">
                <span>{{ setUpdated(item) }}</span>
              
            </template>

            <template v-slot:[`item.category`]="{ item }">
                <span>{{ setCategory(item) }}</span>
              
            </template>

            <template v-slot:[`item.max_get_ranking`]="{ item }">
                <span>{{ setMaxGetRanking(item) }}</span>
              
            </template>

            <template v-slot:[`item.all_updated_count`]="{ item }">
                <span>{{ setAllUpdatedCount(item) }}</span>
              
            </template>

            <template v-slot:[`item.updated_count`]="{ item }">
                <span class="primary--text">{{ setUpdatedCount(item) }}</span>
              
            </template>

            <template v-slot:[`item.keepa_updated_count`]="{ item }">
                <span class="grey--text">{{ setKeepaUpdatedCount(item) }}</span>
              
            </template>

            <template v-slot:[`item.new_items_count`]="{ item }">
                <span class="success--text">{{ setNewItemsCount(item) }}</span>
              
            </template>

            <template v-slot:[`item.min_updated_at`]="{ item }">
                <span>{{ setDtString(item.min_updated_at,'min') }}（{{ setDiff(item.min_updated_at) }}H 経過）</span>
              
            </template>

          </v-data-table>

          </v-tab-item>

        <v-tabs-items>
            
-->
          
    </v-card>

    <v-bottom-sheet v-model="urlcopysheet">
      <v-sheet
        class="text-center"
        >
        
        <v-col cols="12">
          <v-text-field
            ref="qrtarget"
            v-model="qrurl"
            outlined
            dense
            label="URL"
            readonly
            class="mt-2"
            onfocus="this.select();"
            @click="qrdisabled=false"
            @blur="qrdisabled=true"
            v-if="!overlay"
          ></v-text-field>
        </v-col>
        <v-col 
          cols="12"
          class="px-2">
          <v-row no-gutters>
            <v-col cols="auto">
              <v-btn
                text
                @click="closeUrlcopy"
              >
                キャンセル
              </v-btn>
              
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-btn 
                text 
                color="primary"
                class="mb-6"
                @click="onCopy">
                コピー 
              </v-btn>
            </v-col>
            
          </v-row>
        </v-col>
        
      </v-sheet>
    </v-bottom-sheet>
<!--    
    <div id="btnmenu" v-scroll="handleScroll" class="btn-container">
        <v-btn fab small color="blue-grey"
          @click="scrollTop"
            v-if="btm>1000">
          <v-icon color="white">mdi-chevron-up</v-icon>
        </v-btn>
      </div>
            
-->
  </v-container>
</template>

<script>
import Googlemap from "../comps/Googlemap.vue";
import axios from 'axios';
import Swal from 'sweetalert2';
import router from "../../router";
//import { v4 as uuidv4 } from 'uuid'
const { point } = require('@turf/helpers')
const distance = require('@turf/distance').default

export default {
  name: "Trecoder",
  components: {
    'google-map':Googlemap,
  },
  props: {
    baseurl: String,
  },
  //data: () => ({
  data () {
    return {
      loading:false,
      credentials: {},
      access_token: '',
      refresh_token: '',
      email: '',
      username: '',
      page0: 1,
      page1: 1,
      items0: [],
      items1: [],
      items_types:[
        { val: 'タイムレコーダー', key: 'TIME_RECORDER' },
        { val: 'その他', key: 'OTHERS' },
      ],
      selecttab: null,
      tabs: [0,1,2],
      tabs_activedtitle: ['作成履歴','読込履歴'],
      search: '',
      headers: [],
      headers0: [
        { align: 'left',   sortable: true,  groupable: false, text: 'タイトル',     value: 'title' },
        { align: 'left',   sortable: true,  groupable: false, text: '読込可エリア',     value: 'is_latlng' },
        { align: 'left',   sortable: true,  groupable: false, text: '作成日時',     value: 'created_at' },
        { align: 'left',   sortable: true,  groupable: false, text: '更新日時',     value: 'updated_at' },
        { align: 'center', sortable: false, groupable: false, text: '',  value: 'actions' },
      ],
      dialogGooglemap: false,
      valid: true,
      dialog: false,
      dialogtext: '',
      dialogtype: '',
      editedIndex: -1,
      editedItem: {},
      rules: {
          input: [v => !!v || '入力必須',
                  v => (v!=null && v.length<51) || '最大50文字'] ,
          input200: [v => (v!=null && v.length<201) || '最大200文字'] ,
      },
      qrcd: '',
      qrurl: 'サンプル',
      qrdisabled: false,
      qrerror: false,
      copyqr: false,
      absolute: true,
      opacity: 0.5,
      overlay: true,
      map:null,
      myLatLng: {},
      newLatLng:{},
      latlng: null,
      mapOptions: null,
      label: null,
      initPoint: false,
      urlcopysheet: false,
    }
  },
  watch: {
    qrcd(val){
      console.log('qrcd:'+val)
      this.overlay = false
      if(val=='') this.overlay = true
    },
  },
  //}),
  mounted() {
    this.getCookies()
    this.checkToken()
    if(this.access_token!=='') this.getTrecoders()

    //this.initCurrentPosition()
    /*
    const path = this.$route.path
    console.log(this.$route.path)
    if (path === '/register'){
      //this.$router.push({ path: '/register' })
    //} else if (path === '/trend'){
    //  this.isActive = '2'
    } else {
      this.checkLoggedIn();
    }
*/
  },
  methods: {
    //iOSの判定
    isIOS() {
      const agent = window.navigator.userAgent;
      return agent.indexOf("iPhone") != -1 || agent.indexOf("iPad") != -1;
    },
    async initCurrentPosition() {
      try {
        
        const position = await this.getCurrentPosition()
        this.myLatLng.lat = position.coords.latitude
        this.myLatLng.lng = position.coords.longitude
        
        //const accuracy = position.coords.accuracy;
        this.newLatLng.lat = this.myLatLng.lat
        this.newLatLng.lng = this.myLatLng.lng
//console.log('this.newLatLng:'+JSON.stringify(this.newLatLng))

      } catch (error) {
        alert(error)
      }
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        const message = [
          '現在位置が取得できませんでした',
          '位置情報の利用が許可されていません',
          '現在位置が取得できませんでした',
          'タイムアウトになりました',
          '現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。'
        ]
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            response => {
              resolve(response)
            },
            error => {
              reject(message[error.code])
            },
            {
              enableHighAccuracy: false,
              timeout: 5000,
              maximumAge: 0
            }
          )
        } else {
          reject(message[4])
        }
      })
    },

    setLatlng(newLatLng){
      this.newLatLng = newLatLng
      this.myLatLng = newLatLng
    },
    getDistance(newLatLng){
      
      const from = point([Number(this.myLatLng.lng),Number(this.myLatLng.lat)]); //[経度, 緯度] 東京駅
      const to = point([Number(newLatLng.lng),Number(newLatLng.lat)]); //[経度, 緯度] 名古屋駅
      const options = { units: 'kilometers' }; // degrees, radians, miles, kilometersが指定可能

      const result = distance(from, to, options);
      console.log(result*1000) // 267.50358367757696
    },
    selectTab(){
        //alert(JSON.stringify(this.tabs[this.selecttab]))
        /*
        if(this.selecttab==0){

        }else{
        
        }
        */
    },
    getCookies() {
      const CookieValue = this.$cookies.get('CookieValue');
      //console.log(JSON.stringify(CookieValue))
      if(CookieValue){

        this.email = CookieValue.email;
        this.username= CookieValue.username;
        this.access_token = CookieValue.tokens.access;
        this.refresh_token = CookieValue.tokens.refresh;

      }

    },
    setCookies() {
      const cookies = {
        email: this.email,
        username: this.username,
        tokens:{
          access: this.access_token,
          refresh: this.refresh_token
        }
      }

      this.$emit('set-cookies',cookies)
      /*
      this.email = value.email;
      this.username= value.username;
      this.access_token = value.tokens.access;
      this.refresh_token = value.tokens.refresh;

      const cookie = { 
        username: this.username, 
        refresh_token: this.refresh_token
      }
      */

    },
    checkLoggedIn() {
        this.email = '';
        this.username = '';
        this.access_token = '';
        this.refresh_token = '';

        const CookieValue = this.$cookies.get('CookieValue');
//console.log(JSON.stringify(CookieValue))
        if(CookieValue){

            this.email = CookieValue.email;
            this.username= CookieValue.username;
            this.access_token = CookieValue.tokens.access;
            this.refresh_token = CookieValue.tokens.refresh;

        }

        //this.$session.start();
        if (this.email=='') {
            router.push('/login', () => {});
        }
    },
    deleteTrecoqrcds(){
        const BaseRepository = this.createAxiosInstance();
        
        BaseRepository.delete('api/trecoqrcd/' + this.qrcd).then(response => {
            const data = response.data;
            this.loading = false;
            console.log('old-qrcd'+JSON.stringify(this.qrcd))
            if(data==''){
                this.createTrecoqrcds()
            }
            return data;
        });
    },
    setQrcd(){
        this.loading = true;
        const BaseRepository = this.createAxiosInstance();
        
        BaseRepository.get('api/trecoqrcd/' + this.qrcd).then(response => {
            const data = response.data;
            this.loading = false;
            //console.log('get-trecoqrcd:'+JSON.stringify(data))
            if(data!==''){
              console.log('detail:'+JSON.stringify(data.detail))
                if(data.detail==undefined){
                    this.deleteTrecoqrcds()
                }else{
                    this.createTrecoqrcds()
                }
            }
            return data;
        });
    },
    setQrurl(qrcd){
      return this.baseurl + '#/home?cd=' + qrcd + '&type=trecd'
      //return this.baseurl + '%23/home?cd=' + qrcd + '&type=trecd'
    },
    formSubmit(){
      //this.$nextTick(() => this.$refs.form.submit());
      this.$refs.form.$el.submit();
      return false;
    },
    closeDialogGooglemap(){
      this.dialogGooglemap = false
    },
    openDialog(type,item){
        this.initPoint = !this.initPoint
        this.qrcd = ''
        //this.$refs.form.resetValidation()
        //this.initCurrentPosition()

        if(type=='new'){
            this.dialogtext = '登録'
            this.qrurl = 'サンプル'
            this.myLatLng.radius = 10
            this.myLatLng.address = null
            
        }else{
            this.dialogtext = '保存'
            this.editedIndex = this.items0.indexOf(item)
            this.editedItem = Object.assign({}, item)

            this.myLatLng.lat = this.editedItem.lat
            this.myLatLng.lng = this.editedItem.lng
            this.myLatLng.radius = this.editedItem.radius
            this.myLatLng.address = this.editedItem.address
            
            console.log('this.editedItem.trecoderqrcds[0]:'+JSON.stringify(this.editedItem.trecoderqrcds[0]))
            if(this.editedItem.trecoderqrcds[0]!==undefined){
                this.qrcd = this.editedItem.trecoderqrcds[0].id

                this.qrurl = this.setQrurl(this.qrcd)
            }

        }
        //if(this.editedItem.is_latlng) this.initCurrentPosition()
        
        this.dialogtype = type

        //setTimeout(() => {
        this.dialog = true
        //}, 2000);
        
    },
    closeDialog() {
        this.$refs.form.resetValidation()
        this.dialog = false
        
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        
        //console.log('this.items0:'+JSON.stringify(this.items0))
    },

    createAxiosInstance(){
        // axios.create でいきなり axios を呼んだ時に使われる通信部(AxiosInstance)がインスタンス化される
        const axiosInstance = axios.create({
            // この第一引数オブジェクトで設定を定義
            
            // axios で通信する時の URL の頭を決める。大体ドメインとAPI用URL接頭辞
            baseURL: process.env.MIX_BASE_APP_URL,
        });
        
        // interceptors.request.use で送信時に引数に入れた関数が動作する
        // 引数で渡ってくるのは axios の設定(送信先や通信方式も持つ今まさに通信を実行しようとしている設定)で、返り値が通信時に実際に使われる axios の設定になる
        axiosInstance.interceptors.request.use((request)=>{
            // もしヘッダーに API トークンを記述するならば
            //request.headers['Content-Type'] = 'application/json'
            //request.headers['X-Requested-With'] = 'XMLHttpRequest'
            request.headers['Authorization'] = 'JWT ' + this.access_token
            // もし URL に APIトークンを増やすならば
            //request.params = request.params || {};
            //request.params.apiToken = getApiToken();
            
            // リクエスト内容を見るならば
            //console.dir(request)
            
            return request;
        })
    
        // interceptors.response.use で返信時に引数に入れた関数が動作する
        axiosInstance.interceptors.response.use(
            (response)=>response, // 第一引数は通信成功時処理。受けた内容をそのまま通過
            (error)=>{ // 第二引数は通信失敗時処理
                this.loading = false
                this.onLogout()

                // 通信エラーの内容全体をインデント付きのJSONにして alert 表示
                // これだけだととても見難いので適宜プロジェクトに合わせて必要な情報だけ取る処理にした方がベター
                //alert(JSON.stringify(error, ' ', 4))
                Swal.fire({
                type: 'warning',
                title: 'Error',
                text: '処理に失敗しました:'+error.message,
                showConfirmButton:false,
                showCloseButton:false,
                timer:3000
                })
            }
        )
    
        // interceptor で共通処理を追加した通信機能を返す。
        return axiosInstance;
    },
    saveItem(){
      if (this.$refs.form.validate()) {

        const BaseRepository = this.createAxiosInstance();
        console.log('this.editedItem.is_latlng:'+JSON.stringify(this.editedItem.is_latlng))
        let update = {
                      'type': 'TIME_RECORDER',
                      'title': this.editedItem.title,
                      'is_latlng': this.editedItem.is_latlng,
                    }
        
        if(this.editedItem.description!=='') update['description'] = this.editedItem.description
console.log('this.newLatLng:'+JSON.stringify(this.newLatLng))
        if(this.editedItem.is_latlng){
          update['lat'] = this.newLatLng.lat
          update['lng'] = this.newLatLng.lng
          update['radius'] = this.newLatLng.radius
          update['address'] = this.newLatLng.address
          console.log('update:'+JSON.stringify(update))
        }else{
          update['lat'] = null
          update['lng'] = null
          update['radius'] = null
          update['address'] = null
        }

        if(this.dialogtype=='new'){

            BaseRepository.post('api/trecos/', update).then(response => {
                const data = response.data;
                console.log('data:'+JSON.stringify(data))
                
                if(data.id!==''){                 
                    this.editedItem = data
                    this.createTrecoqrcds()
                }
                
                return data;
            });
            
        }else{

            BaseRepository.patch('api/treco/'+this.editedItem.id, update).then(response => {
                const data = response.data;
                if(data.id!==''){
                    this.editedItem = data
                    this.qrcd = this.editedItem.trecoderqrcds[0].id
                    this.qrurl = this.setQrurl(this.qrcd)
                    this.getTrecoders()
                }
                
                console.log('data:'+JSON.stringify(data))
                return data;
            });
        }
        
      }
    },
    saveItem0(){
        const BaseRepository = this.createAxiosInstance();
        let update = {
          'type': this.editedItem.type.key,
          'title': this.editedItem.title,
        }
        if(this.editedItem.description!=='') update['description'] = this.editedItem.description
        if(this.dialogtype=='new'){
            /*
            BaseRepository.post('api/trecos/', update).then(response => {
                const data = response.data;
                this.editedItem = data
                this.qrurl = this.setQrurl(this.editedItem.trecoderqrcds[0].id)
                return data;
            });
            */
          //console.log('this.editedItem.id:'+JSON.stringify(this.editedItem.id))
          
        }else{
            //update.qrcd = uuidv4()
            //console.log('update:'+JSON.stringify(update))
            BaseRepository.patch('api/treco/'+this.editedItem.id, update).then(response => {
                const data = response.data;
                this.editedItem = data
                this.qrcd = this.editedItem.trecoderqrcds[0].id
                this.qrurl = this.setQrurl(this.qrcd)
                //console.log('data:'+JSON.stringify(data))
                return data;
            });
        }
    },
    deleteTitle(item){
        return item.title
    },

    setDeleteItem(item){
        if(item) return false
        //if(item.active==0 && item.updated_count==0) return false
        //return true
    },
    deleteItem(){
        this.loading = true;
        
        const BaseRepository = this.createAxiosInstance();
        
        BaseRepository.delete('api/treco/' + this.editedItem.id).then(response => {
            const data = response.data;
            this.loading = false;
            //console.log(JSON.stringify(res.data))
            if(data==''){
                this.getTrecoders()
            }
            return data;
        });

/*      
      //axios.delete('api/trecoqrcd/' + this.editedItem.trecoderqrcds[0].id,
      axios.delete('api/treco/' + this.editedItem.id,
        {
          headers: { 
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'JWT ' + this.access_token
          }
        },
        ).then(res => {
            this.loading = false;
            //console.log(JSON.stringify(res.data))
            if(res.data==''){
                this.getTrecoders()
            }

          //this.items.splice(this.editedIndex, 1)


        }).catch(e =>{
            this.loading = false;
            Swal.fire({
            type: 'warning',
            title: 'Error',
            text: '削除に失敗しました:'+e.message,
            showConfirmButton:false,
            showCloseButton:false,
            timer:3000
            })
        })
        */
      /*
      axios.delete('api/treco/' + this.editedItem.id,
        {
          headers: { 
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'JWT ' + this.access_token
          }
        },
        ).then(res => {
            this.loading = false;
            console.log(JSON.stringify(res.data))
            if(res.data==''){
                this.getTrecoders()
            }

          //this.items.splice(this.editedIndex, 1)


        }).catch(e =>{
            this.loading = false;
            Swal.fire({
            type: 'warning',
            title: 'Error',
            text: '削除に失敗しました:'+e.message,
            showConfirmButton:false,
            showCloseButton:false,
            timer:3000
            })
        })
*/
    },
    closeDelete () {

        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })

    },
    createTrecoqrcds(){
        const BaseRepository = this.createAxiosInstance();
        let update = {
                      'treco': this.editedItem.id,
                    }
        //console.log('update:'+JSON.stringify(update));

        setTimeout(() => {
            BaseRepository.post('api/trecoqrcds/', update).then(response => {
                const data = response.data;
                if(data.id!==''){
                    console.log('createTrecoqrcds:'+JSON.stringify(data));
                    //this.editedItem.trecoderqrcds[0].id = String(data.id)
                    this.$set(this.editedItem.trecoderqrcds,0,data)
                    this.qrcd = this.editedItem.trecoderqrcds[0].id
                    this.qrurl = this.setQrurl(this.qrcd)
                    this.dialogtype='edit'
                    this.getTrecoders()
                }
                
                return data;
            });
        }, 500);
    },
    getTrecoders() {
        //console.log(JSON.stringify(this.access_token))
        this.loading = true
        setTimeout(() => {
          const BaseRepository = this.createAxiosInstance();
        
          BaseRepository.get('api/trecos/').then(response => {
              const data = response.data;
              this.loading = false;
              //console.log('get-trecoqrcd:'+JSON.stringify(data))
              if(data!==''){
                this.items0 = data.results

                if(this.dialog && this.dialogtype=='delete') this.closeDialog()
              }
              return data;
          });
          /*
            axios.get('api/trecos/', 
              {
                headers: { 
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'XMLHttpRequest',
                  'Authorization': 'JWT ' + this.access_token
                }
              },
            ).then(res => {
              this.loading = false
              //console.log('res.data:'+JSON.stringify(res.data))
              this.items0 = res.data.results

              if(this.dialog && this.dialogtype=='delete') this.closeDialog()
                  // eslint-disable-next-line
            }).catch(error => {
              this.loading = false
              console.log('error:'+JSON.stringify(error));
              this.onLogout()
              //this.onLogout();
      //this.errorHandler(res, error)
            })
            */

        }, 1000);
        

    },
    checkToken() {
        this.loading = true
        const refreshTokens = { refresh: this.refresh_token }
        axios.post('api/auth/token/refresh/', refreshTokens).then(res => {
            //console.log('res.data.access:'+JSON.stringify(res.data.access))
            this.loading = false
            if(res.data.access!=='' && res.data.access!==undefined){
              //console.log('ok:'+JSON.stringify(res.data))
  
              this.access_token = res.data.access

              this.setCookies()
              
            }else{
              console.log('ng:'+JSON.stringify(res.data))
              this.onLogout()
            }
                //this.$cookies.set('email', res.data.email);
                //this.$cookies.set('username', res.data.username);

            // eslint-disable-next-line
        }).catch(e => {
          this.loading = false
          console.log(e.message);
            //this.logouted();
          this.onLogout()
        })
        
        /*
        const results = await axios.post(this.baseurl+'api/auth/token/refresh/', 
                  {
                  refresh: this.refresh_token
                  },
                  );
        const { data } = results;

        console.log('actions:'+data.results)
            */
    },
    onLogout(){
      this.$cookies.set('RoutePath', this.$route.path);
      this.$emit('on-logout')
    },
    setIsLatlngString(item){
      let result = '-'
      if(item.is_latlng) result = item.address + '（半径' + item.radius + 'm）'
      return result
    },
    setDtString(item){
      let date = Date.parse(item);
      //let date = item.replace(/-/g, '/') 
      date = new Date(date)
      date = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' +('0' + date.getDate()).slice(-2) + ' ' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
      
      return date
    },
    formatDate(date){
      //let formatted_date = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' +('0' + date.getDate()).slice(-2) + ' ' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2) + '.' + date.getMilliseconds()
      let formatted_date = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' +('0' + date.getDate()).slice(-2) + ' ' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)
      return formatted_date;
    },
    closeUrlcopy(){
      this.urlcopysheet = false

    },
    urlCopy(){
      this.urlcopysheet = true

    },
    onCopy(){
      navigator.clipboard.writeText(this.qrurl)
      this.copyqr = true
        //if (this.isIOS()) {
        //  alert('ios')
            //iOSの場合
            /*
            const doc: HTMLInputElement = document.getElementById(
              "copy-text"
            ) as HTMLInputElement;
            const selected = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(doc);
            selected!.removeAllRanges();
            selected!.addRange(range);
            document.execCommand("copy");

            */
          //} else {

        //  }
          /*
        this.$refs.qrtarget.focus()

        const self = this
        setTimeout(() => {
        this.$copyText(this.qrurl).then(function (e) {
          alert(e)
          //self.copyqr = true
            //console.log(e)
        }, function (e) {
          if(e) self.qrerror = true
          //alert(e)
            //alert('Can not copy')
            //console.log(e)
        })
        }, 1000);
        */
    },
    saveCanvas(saveType){
        let imageType = "image/png";
        let fileName = this.qrfilename+".png";

        if(saveType === "jpeg"){
            imageType = "image/jpeg";
            //fileName = "sample.jpg";
        }
        let canvas = document.getElementById("qrcode").getElementsByTagName("canvas");
        //var canvas = document.getElementById("myCanvas");
        // base64エンコードされたデータを取得 「data:image/png;base64,iVBORw0k～」
        let base64 = canvas[0].toDataURL(imageType);
        // base64データをblobに変換
        let blob = this.Base64toBlob(base64);
        // blobデータをa要素を使ってダウンロード
        this.saveBlob(blob, fileName);
    },

    // Base64データをBlobデータに変換
    Base64toBlob(base64)
    {
        // カンマで分割して以下のようにデータを分ける
        // tmp[0] : データ形式（data:image/png;base64）
        // tmp[1] : base64データ（iVBORw0k～）
        let tmp = base64.split(',');
        // base64データの文字列をデコード
        let data = atob(tmp[1]);
        // tmp[0]の文字列（data:image/png;base64）からコンテンツタイプ（image/png）部分を取得
        let mime = tmp[0].split(':')[1].split(';')[0];
        //  1文字ごとにUTF-16コードを表す 0から65535 の整数を取得
        let buf = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
              buf[i] = data.charCodeAt(i);
          }
          // blobデータを作成
        let blob = new Blob([buf], { type: mime });
        return blob;
    },

    // 画像のダウンロード
    saveBlob(blob, fileName)
    {
        let url = (window.URL || window.webkitURL);
        // ダウンロード用のURL作成
        let dataUrl = url.createObjectURL(blob);
        // イベント作成
        //let event = document.createEvent("MouseEvents");
        //event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        let event = new MouseEvent('click', {view: window, bubbles: false, cancelable: false});
        // a要素を作成
        let a = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
        // ダウンロード用のURLセット
        a.href = dataUrl;
        // ファイル名セット
        a.download = fileName;
        // イベントの発火
        a.dispatchEvent(event);
    },
    logout() {
      this.$emit('check-logout',this.access_token)

        //this.$session.clear();

        /*
        axios.post('https://qrtimes.giverth.net/api/auth/logout/', 
          {
           refresh: this.refresh_token
          },
          {
           headers: { Authorization: 'JWT ' + this.access_token }
          },
          ).then(res => {
                //this.$cookies.set('email', res.data.email);
                //this.$cookies.set('username', res.data.username);
console.log(JSON.stringify(res.data))
                this.$cookies.remove('CookieValue');
                router.push("/login");
            // eslint-disable-next-line
            }).catch(e => {
              console.log(e.message);
                this.$cookies.remove('CookieValue');
                router.push("/login");

            })
*/
    }
  }
};
</script>