<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      elevation="1"
      v-if="loading && username==''"
    >
    <!--
      <div class="d-flex align-center">

        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>
      -->
      <v-toolbar-title>QRTimes</v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-btn 
       @click="pageLogin"
        text
       >
        <v-icon>mdi-open-in-new</v-icon>
        <span class="mr-2">ログイン</span>
      </v-btn>
      <v-btn
        @click="pageRegisterEmail"
        text
      >
        <v-icon>mdi-open-in-new</v-icon>
        <span class="mr-2">新規登録</span>
      </v-btn>

    </v-app-bar>

    <v-app-bar 
      app 
      color="white"
      elevation="1"
      clipped-left
      v-else>
      <v-app-bar-nav-icon @click="drawerClick"></v-app-bar-nav-icon>
      <v-toolbar-title>QRTimes</v-toolbar-title>
      <v-spacer></v-spacer>
        
        <v-menu
          offset-y
          left
          bottom
        >

          <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-avatar
              size="40px"
            >
              <img
                alt="Avatar"
                :src="icon48"
              >

            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
            <v-avatar
              size="36px"
              >
              <img
                alt="Avatar"
                :src="icon48"
              >

            </v-avatar>
              <h6>
                {{ username }}
                <v-btn
                x-small
                icon
                @click="btnAccount"
                >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              </h6>
              <p class="caption mt-1 mx-2">
                {{ email }}
              </p>
              
              <v-divider class="my-1"></v-divider>
              <v-btn
                depressed
                text
                @click="checkLogout(access_token)"
              >
                ログアウト
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
      
    </v-app-bar>


    <v-navigation-drawer
      app
      v-model="drawer"
      clipped
      color="white"
      v-if="username!==''"
      >
      <v-list dense>
        <template v-for="(listitem,index) in listitems">
          <v-divider
            v-if="listitem.divider" 
            :key="index"
          ></v-divider>
          <v-subheader
            v-if="listitem.divider"
            :key="listitem.header"
            >
            {{ listitem.header }}
          </v-subheader>

          <v-list-item 
            v-if="!listitem.lists" 
            :to="listitem.path"
            :key="listitem.name"
            @click="listClick(index,listitem.path,false)">
                <v-list-item-icon>
                  <v-icon>{{ listitem.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ listitem.meta.name }}
                    <v-badge
                    :color="listitem.badgecolors[listitem.badgecoloridx]"
                    :content="listitem.badge"
                    :value="listitem.badge"
                    >
                    </v-badge>
                  </v-list-item-title>
                  
                </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            no-action
            :prepend-icon="listitem.meta.icon"
            :key="listitem.name"
            v-model="listitem.active"
            @click="listClick(index,listitem.path,listitem.active)">
                <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ listitem.meta.name }}
                        <v-badge
                          :color="listitem.badgecolors[listitem.badgecoloridx]"
                          :content="listitem.badge"
                          :value="listitem.badge"
                          >
                        </v-badge>
                      </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item
                    v-for="list in listitem.lists"
                    :to="list.path"
                    :key="list.name"
                  >
                  <v-list-item-title>
                    {{ list.name }}
                    <v-badge
                      :color="list.badgecolors[list.badgecoloridx]"
                      :content="list.badge"
                      :value="list.badge"
                      >
                    </v-badge>
                  </v-list-item-title>
                </v-list-item>
          </v-list-group>

        </template>
      </v-list>

    </v-navigation-drawer>
<!-- 色付きドロワー 
  <v-bottom-navigation
      app
      v-model="bmenu"
      color="indigo"
      grow
      v-if="!drawer"
      
    >
    <template v-for="(listitem,index) in listitems">
      <v-btn 
        v-if="!listitem.lists" 
        :key="index"
        @click="bmenuClick(index,listitem.path)">
        <span>{{ listitem.meta.name }}</span>
        <v-icon>{{ listitem.meta.icon }}</v-icon>
        <v-badge
          :color="listitem.badgecolors[listitem.badgecoloridx]"
          :content="listitem.badge"
          :value="listitem.badge"
          >
        </v-badge>
      </v-btn>

      <v-menu
        v-else
        top
        :nudge-width="45"
        :offset-y="offset"
        :key="index"
      >
        <template 
          v-slot:activator="{ on, attrs }" 
           >
          <v-btn
            :key="index"
            v-bind="attrs"
            v-on="on"
            @click="bmenuClick(index,listitem.path)"
          >
          <span>{{ listitem.meta.name }}</span>
          <v-icon>{{ listitem.meta.icon }}</v-icon>
            <v-badge
              :color="listitem.badgecolors[listitem.badgecoloridx]"
              :content="listitem.badge"
              :value="listitem.badge"
              >
            </v-badge>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="list in listitem.lists"
            :to="list.path"
            :key="list.name"
          >
            <v-list-item-title>
                {{ list.name }}
                <v-badge
                  :color="list.badgecolors[list.badgecoloridx]"
                  :content="list.badge"
                  :value="list.badge"
                  >
                </v-badge>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


      </template>
  </v-bottom-navigation>
 -->

    <v-main>
      <router-view
        :rules="rules"
        :baseurl="baseurl"
        @set-cookies="setCookies"
        @check-token="checkToken"
        @check-logout="checkLogout"
        @on-logout="onLogout">
      </router-view>
    </v-main>
  </v-app>

</template>

<script>
import axios from 'axios';
import router from "./router";
import zxcvbn from 'zxcvbn';

export default {
  name: 'App',
//data: () => ({
  data () {
    return {
      baseurl: 'https://qrtimes.giverth.net/',
      loading: false,
      drawer: null,
      qrcd: null,
      qrtype: null,
      listnames: [],
      listnames0: ['home','trecos','qradmin'],
      listnames7: ['home','trecos','outacts','intras'],
      listnames10: ['home','trecos','outacts'],
      listitems: [],
      icon48: '',
      access_token: '',
      refresh_token: '',
      email: '',
      username: '',
      rules: {
          email: [
              v => !!v || "メールアドレスは必須です",
              v => (v || '').length <= 70 || "メールアドレスは20文字以下でなければなりません",
              v => {
              const pattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(v) || 'メールアドレス形式ではないです'
              },
          ],
          username:[
              v => !!v || '入力必須',
          ],
          password: [
              v => !!v || "パスワードは必須です",
          ],
          required: v => !!v || '入力必須',
          min: v => (v && v.length >= 9)  || '9～20文字',
          // パスワード強度のバリデーションです。
          // zxcvbn(v).scoreで入力値のスコアを算出して、許容するスコア （ここでは３） と比較しています。
          strength: v => zxcvbn(v).score >= 2 || 'パスワードの安全性が低いです'
      }
    }
  },
  //}),
  mounted() {
    this.loading = false
    this.getCookies()
    this.setLists()
    
    if(this.access_token!==''){

      this.qrcd = this.$route.query.cd
      this.qrtype = this.$route.query.type
      if(this.qrcd!=null){
        router.push({ path: '/home' , query :{ cd: this.qrcd, type: this.qrtype }}, () => {})
      }else{
        router.push(this.$route.path, () => {});
      }

    }
  },
  methods: {
    setLists(){
        this.listnames = this.listnames0
        /*
        if(this.login_role>7){
          this.listnames = this.listnames10
          
        }else if(this.login_role==7){
          this.listnames = this.listnames7
        }
        */
        //alert(JSON.stringify(this.listnames))
                     
        this.listitems = []
        for(var i=0 ; i<this.listnames.length; i++) {
          //console.log('this.$router.options.routes:'+JSON.stringify(this.$router.options.routes))
          for(var j=0 ; j<this.$router.options.routes.length; j++) {
            if (this.$router.options.routes[j].name == this.listnames[i] ) {
              /*
              if(this.listnames[i]=='intra_attends'){
                this.intrasbadge = this.newintras + this.updateintras
                
                this.$set(this.$router.options.routes[j],'badge',this.intrasbadge)
                this.$set(this.$router.options.routes[j].lists[0],'badge',this.intrasbadge)
                this.$set(this.$router.options.routes[j],'badgecoloridx',this.intrasbadgecoloridx)
                this.$set(this.$router.options.routes[j].lists[0],'badgecoloridx',this.intrasbadgecoloridx)
              }
              
              
              if(this.listnames[i]=='admin_users'){
                this.$set(this.$router.options.routes[j],'badge',this.actived0)
              }
              //alert(this.login_role)
              if(this.login_role==7 && this.listnames[i]=='intras'){
                //this.$set(this.$router.options.routes[j],'divider',true)
                this.$set(this.$router.options.routes[j],'header','運用担当者')

                //alert('listitems:'+JSON.stringify(this.$router.options.routes[j]))
              }
              */
              this.listitems.push(this.$router.options.routes[j])
              break
            }
          }
          
        }

    },
    listClick(index,active){
        /*
        this.bottommenu = index
        //alert(index)
        if(index>2){
          this.bgstyle = this.bgstyle_admin
        }else{
          this.bgstyle = this.bgstyle_user
        }
        */
        
        if(!active) this.listitems.forEach( listitem => listitem.active = false)

        for(var i=0 ; i<this.listitems.length; i++) {
          if(i==index) this.$set(this.listitems[i],'active',active)
        }

        //alert('listitems:'+JSON.stringify(this.listitems))
    },
    drawerClick(){
        this.drawer = !this.drawer
    },
    pageLogin() {
        router.push("/login", () => {});
    },
    pageRegisterEmail() {
        router.push("/register-email", () => {});
    },
    getCookies() {
      const CookieValue = this.$cookies.get('CookieValue');
      //console.log(JSON.stringify(CookieValue))
      if(CookieValue){

        this.email = CookieValue.email;
        this.username= CookieValue.username;
        this.access_token = CookieValue.tokens.access;
        this.refresh_token = CookieValue.tokens.refresh;
        

      }
      this.loading = true
    },
    setCookies(value) {
      this.loading = false
      this.$cookies.config(60 * 60,'');
      this.$cookies.set('CookieValue', value);

      this.getCookies()
      /*
      this.email = value.email;
      this.username= value.username;
      this.access_token = value.tokens.access;
      this.refresh_token = value.tokens.refresh;

      const cookie = { 
        username: this.username, 
        refresh_token: this.refresh_token
      }
      */

    },
    checkLogout(access_token) {

      axios.post('api/auth/logout/', 
        {
          refresh: this.refresh_token
        },
        {
          headers: { 
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'JWT ' + access_token
          }
        },
      ).then(res => {
        
                //this.$cookies.set('email', res.data.email);
                //this.$cookies.set('username', res.data.username);
        //console.log(JSON.stringify(res.data))
        if(res.data){
          this.$cookies.remove('RoutePath');
          this.onLogout();
        }
            // eslint-disable-next-line
      }).catch(error => {
        //console.log('error:'+JSON.stringify(error));
        this.onLogout();
//this.errorHandler(res, error)
      })

        //this.$session.clear();
        //this.access_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM4MTg1MDYyLCJqdGkiOiI2YTk2OGY4ZjYwOGM0MTVjODAxMzI2NWU4MDljOTVkYSIsInVzZXJfaWQiOjF9.WhEf8Yq2s8546Bt_k1yjMJ4UscguT6K4i6uP9_lYmD8'
//this.refresh_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTYzODI1OTY4OCwianRpIjoiNGU4YTUyOTc2NzgyNDk1Nzk1NDk4NzUwZTgxZmY3ZTEiLCJ1c2VyX2lkIjoxfQ.XnAvUvucD60xomOlPUzI2B9a0mWploOI84XOXnPlflI'
/*
axios.post(baseUrl+'api/auth/logout/', 
          {
           headers: { Authorization: 'JWT ' + this.access_token }
          },
          async (req, res) => {
              try {
                  //const countries = await instance.get('countries')
                  res.send({ refresh: this.refresh_token },)
              } catch (error) {
                  this.errorHandler(res, error)
              }
          })
          */

    },
    
    onLogout(){
        //this.$cookies.remove('RoutePath');
        this.$cookies.remove('CookieValue');
        this.email = ''
        this.username = ''
        this.access_token = ''
        this.refresh_token = ''
        router.push("/login", () => {});
    }
  }

};
</script>
<!--
<style scoped lang="scss">
.v-app-bar {
  background: -moz-linear-gradient(65deg, #77a2ff, #b7e8ff); 
  background: -webkit-linear-gradient(65deg, #77a2ff, #b7e8ff); 
  background: linear-gradient(25deg, #77a2ff, #b7e8ff); 
}
</style>
-->