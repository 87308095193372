<template>
    <v-container grid-list-md>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm8 lg4 md5>
                <v-card class="login-card">
                    <v-card-title>
                    <span class="headline">新規登録</span>
                    </v-card-title>
                    
                    <v-spacer />

                    <v-card-text>

                    <v-layout
                        row
                        fill-height
                        justify-center
                        align-center
                        v-if="loading"
                    >
                        <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                        />
                    </v-layout>


                    <v-form v-else-if="!loading && !alert" ref="form" v-model="valid" lazy-validation>
                        <v-container>
                        
                        <v-row>
                            <v-col
                                cols="12">
                                
                                <p class="mb-0 font-weight-bold">名前</p>
                                <v-text-field
                                    v-model="credentials.username"
                                    :counter="10"
                                    maxlength="10"
                                    :rules="rules.username"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12">
                                
                                <p class="mb-0 font-weight-bold">パスワード</p>
                                <password-text-field v-model="password" :rules="[rules.required, rules.min, rules.strength]" />
                                <!--
                                <v-text-field
                                    v-model="password"
                                    :counter="20"
                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.required, rules.min, rules.strength]"
                                    validate-on-blur
                                    :type="show_password ? 'text' : 'password'"
                                    :disabled="confirm"
                                    @click:append="show_password = !show_password"
                                ></v-text-field>
                                -->
                                <v-progress-linear
                                    :background-opacity="opacity"
                                    :color="score.color"
                                    :value="score.value"
                                    v-if="password!==''"
                                ></v-progress-linear>
                                <!-- <input type="hidden" id="password" name="password" v-model="password"> -->
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="!confirm">
                            <v-col
                                cols="12">
                                
                                <p class="mb-0 font-weight-bold">パスワード（確認用）</p>
                                <password-text-field v-model="confirm_password" :rules="rules2.confirmpassword" />
                                <!--
                                <v-text-field
                                    v-model="confirm_password"
                                    :append-icon="show_confirm_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="rules.confirmpassword"
                                    :type="show_confirm_password ? 'text' : 'password'"
                                    required
                                    @click:append="show_confirm_password = !show_confirm_password"
                                ></v-text-field>
                                <input type="hidden" id="password_confirmation" name="password_confirmation" v-model="password_confirmation">
                                -->
                            </v-col>
                        </v-row>
                        
                        </v-container>
                        
                        <v-btn class="primary" :disabled="!valid" @click="onSave">登 録</v-btn>

                    </v-form>
                    <div class="px-4 my-4" v-if="emailtokens!==''">
                            <v-btn v-if="!confirm" :disabled="!valid" color="primary" elevation="0" block large @click="onConfirm">
                            確 認
                            </v-btn>
                            <v-btn v-if="confirm" elevation="0" block large @click="confirm=false">
                            戻 る
                            </v-btn>
                    </div>
                    <v-alert
                        dense
                        text
                        :type="alertype"
                        justify="center" 
                        v-if="alert"
                        >
                        {{ alertmsg }}
                    </v-alert>
                    <v-row v-if="alert">
                        <v-col class="text-center">
                            <v-btn text color="primary" @click="pageRegisterEmail">新規登録画面へ</v-btn>
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import PasswordTextField from "../forms/PasswordTextField.vue";
import axios from 'axios';
//import VueCookies from 'vue-cookies';
import Swal from 'sweetalert2';
import router from '../../router';
import zxcvbn from 'zxcvbn';

export default {
    name: 'Register',
    components: {
        'password-text-field': PasswordTextField,
    },
    props: {
        rules: Object
      //logined: Boolean,
    },
    //data: () => ({
    data () {
      return {
        alert: false,
        alertype: '',
        alertmsg: '',
        credentials: {},
        confirm: false,
        emailtokens: '',
        password: '',
        confirm_password: '',
        valid:true,
        loading:false,
        cookievalue: '',
        rules2: {
            confirmpassword:[
                v => !!v || '入力必須',
                v => v === this.password || '不一致',
            ],
        },
      }
    },
    //}),
    mounted() {
        this.loading = true;
        this.credentials.token = this.$route.query.token
        this.checkToken()
    },
    computed: {
        score(){
            // zxcvbnで入力されたパスワードの強度をチェックしています。
            const result = zxcvbn(this.password);

            // switch文を使って、パスワードのスコアによって、返すcolorとvalueを分岐しています。
            // ここで使っているcolorの値はVuetifyで定義されているものです。
            switch (result.score) {
            case 4:
                return {
                color: "light-blue",
                value: 100
                };
            case 3:
                return {
                color: "light-green",
                value: 75
                };
            case 2:
                return {
                color: "yellow",
                value: 50
                };
            case 1:
                return {
                color: "orange",
                value: 25
                };
            default:
                return {
                color: "red",
                value: 0
                };
            }
        },
    },
/*    watch: {
        confirm_password (val) {
            this.valid = true
            if(this.password!==val){
                this.valid = false
                alert(this.password)
            }
        }
    }, */
    methods: {
        checkToken(){
            axios.get('api/auth/register-token/?token='+this.credentials.token).then(res => {
            //console.log('res.data:'+JSON.stringify(res.data))
                if(res.data.error!=='' && res.data.error!==undefined){
                    console.log(JSON.stringify(res.data))
                    this.alert = true
                    this.alertype = 'error';
                    this.alertmsg = 'ユーザー登録に有効なパスではありません。';
                }else{
                    console.log(JSON.stringify(res.data))
                    this.credentials.email = res.data.email
                }

                this.loading = false;

                //this.$session.start();
                //this.$session.set('token', res.data);
                //router.push('/');
            // eslint-disable-next-line
            }).catch(e => {
                this.loading = false;
                Swal.fire({
                type: 'warning',
                title: 'Error',
                text: '認証に失敗しました',
                showConfirmButton:false,
                showCloseButton:false,
                timer:3000
                })
            })
        },
        pageRegisterEmail() {
            router.push("/register-email", () => {});
        },
        onConfirm(){
            this.$refs.form.validate()
            
            setTimeout(() => {
            //alert(this.valid)
            if(this.valid) this.confirm = true

            },50)

        },
        onSave() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.alert = false;
                this.alertype = 'error';
                this.alertmsg = 'ユーザー登録に失敗しました。';

                this.credentials.password = this.password

//console.log('this.credentials:'+JSON.stringify(this.credentials))
                axios.patch('api/auth/register/', this.credentials).then(res => {
    //console.log(JSON.stringify(res.data.errors))
                    if(res.data.errors!=='' && res.data.errors!==undefined){
                        //console.log(JSON.stringify(res.data))
                        
                    }else{
                        //console.log(JSON.stringify(res.data))
                        this.alertype = 'success';
                        this.alertmsg = 'ユーザー登録が完了しました。';
                    }
                    this.alert = true
                    this.loading = false;

                    //this.$session.start();
                    //this.$session.set('token', res.data);
                    //router.push('/');
                // eslint-disable-next-line
                }).catch(e => {
                    this.loading = false;
                    Swal.fire({
                    type: 'warning',
                    title: 'Error',
                    text: '登録に失敗しました',
                    showConfirmButton:false,
                    showCloseButton:false,
                    timer:3000
                    })
                })
            }
        }
    }
}
</script>