<template>
    <v-container grid-list-md>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm8 lg4 md5>
                <v-card class="login-card">
                    <v-card-title>
                    <span class="headline">新規登録</span>
                    </v-card-title>
                    
                    <v-spacer />

                    <v-card-text>

                    <v-layout
                        row
                        fill-height
                        justify-center
                        align-center
                        v-if="loading"
                    >
                        <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                        />
                    </v-layout>


                    <v-form v-else ref="form" v-model="valid" lazy-validation>
                        <v-container>
                        <v-row>
                            <v-col
                                cols="12">
                                
                                <p class="mb-0 font-weight-bold">メールアドレス</p>
                                <email-text-field v-model="credentials.email" :rules="rules.email" />

                                <v-alert
                                    dense
                                    text
                                    :type="alertype"
                                    justify="center" 
                                    v-if="alert"
                                    >
                                    {{ alertmsg }}
                                </v-alert>
                            </v-col>
                        </v-row>
                                                
                        </v-container>
                        <v-btn class="primary" :disabled="!valid" @click="onSave">登 録</v-btn>

                    </v-form>
                    <div class="px-4 my-4" v-if="emailtokens!==''">
                            <v-btn v-if="!confirm" :disabled="!valid" color="primary" elevation="0" block large @click="onConfirm">
                            確 認
                            </v-btn>
                            <v-btn v-if="confirm" elevation="0" block large @click="confirm=false">
                            戻 る
                            </v-btn>
                    </div>

                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import EmailTextField from "../forms/EmailTextField.vue";

import axios from 'axios';
//import VueCookies from 'vue-cookies';
import Swal from 'sweetalert2';
//import router from '../../router';
import zxcvbn from 'zxcvbn';

export default {
    name: 'Register',
    components: {
        'email-text-field': EmailTextField,
    },
    props: {
        rules: Object
      //logined: Boolean,
    },
    //data: () => ({
    data () {
      return {
        alert: false,
        alertype: '',
        alertmsg: '',
        credentials: {},
        confirm: false,
        emailtokens: '',
        password: '',
        confirm_password: '',
        valid:true,
        loading:false,
        cookievalue: '',
        rules2: {
            confirmpassword:[
                v => !!v || '入力必須',
                v => v === this.password || '不一致',
            ],
        }
      }
    },
    //}),
    computed: {
        score(){
            // zxcvbnで入力されたパスワードの強度をチェックしています。
            const result = zxcvbn(this.password);

            // switch文を使って、パスワードのスコアによって、返すcolorとvalueを分岐しています。
            // ここで使っているcolorの値はVuetifyで定義されているものです。
            switch (result.score) {
            case 4:
                return {
                color: "light-blue",
                value: 100
                };
            case 3:
                return {
                color: "light-green",
                value: 75
                };
            case 2:
                return {
                color: "yellow",
                value: 50
                };
            case 1:
                return {
                color: "orange",
                value: 25
                };
            default:
                return {
                color: "red",
                value: 0
                };
            }
        },
    },
/*    watch: {
        confirm_password (val) {
            this.valid = true
            if(this.password!==val){
                this.valid = false
                alert(this.password)
            }
        }
    }, */
    methods: {
        onConfirm(){
            this.$refs.form.validate()
            
            setTimeout(() => {
            //alert(this.valid)
            if(this.valid) this.confirm = true

            },50)

        },
        onSave() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.alert = false;
                this.alertype = 'error';
                this.alertmsg = 'このメールアドレスは、すでに登録してあります。';

                //this.credentials.password = this.password

//console.log('this.credentials:'+JSON.stringify(this.credentials))
                axios.post('api/auth/register-email/', this.credentials).then(res => {
    //console.log(JSON.stringify(res.data.errors))
                    if(res.data.errors!=='' && res.data.errors!==undefined){
                        console.log(JSON.stringify(res.data))
                        
                    }else{
                        this.alertype = 'success';
                        this.alertmsg = '上記メールアドレス宛に、ユーザー登録URLを送信しました。';
                    }
                    this.alert = true
                    this.loading = false;

                    //this.$session.start();
                    //this.$session.set('token', res.data);
                    //router.push('/');
                // eslint-disable-next-line
                }).catch(e => {
                    this.loading = false;
                    Swal.fire({
                    type: 'warning',
                    title: 'Error',
                    text: '登録に失敗しました',
                    showConfirmButton:false,
                    showCloseButton:false,
                    timer:3000
                    })
                })
            }
        }
    }
}
</script>