<template>
    <v-container grid-list-md>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm8 lg4 md5>
                <v-card class="login-card">
                    <v-card-title>
                    <span class="headline">ログイン</span>
                    </v-card-title>

                    <v-spacer/>

                    <v-card-text>

                    <v-layout
                        row
                        fill-height
                        justify-center
                        align-center
                        v-if="loading"
                    >
                        <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                        />
                    </v-layout>


                    <v-form v-else ref="form" v-model="valid" lazy-validation>
                        <v-container>
                        <v-row>
                            <v-col
                                cols="12">
                                
                                <p class="mb-0 font-weight-bold">メールアドレス</p>
                                <!--
                                <v-text-field
                                    v-model="credentials.email"
                                    :counter="70"
                                    maxlength="70"
                                    :rules="rules.email"
                                /> -->
                                <email-text-field v-model="credentials.email" :rules="rules.email" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12">
                                
                                <p class="mb-0 font-weight-bold">パスワード</p>
                                <password-text-field v-model="credentials.password" :rules="rules.password" />
                                <v-alert
                                    dense
                                    text
                                    :type="alertype"
                                    justify="center" 
                                    v-if="alert"
                                    >
                                    {{ alertmsg }}
                                </v-alert>
<!--
                                <v-text-field
                                    v-model="credentials.password"
                                    :counter="20"
                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="rules.password"
                                    validate-on-blur
                                    :type="show_password ? 'text' : 'password'"
                                    @click:append="show_password = !show_password"
                                ></v-text-field>
-->                                
                            </v-col>
                        </v-row>
<!--
                        <v-text-field
                            type="password"
                            v-model="credentials.password"
                            :counter="20"
                            label="パスワード"
                            maxlength="20"
                            required
                        />
-->
                        </v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                class="mt-4 pt-4">
                                <v-btn color="primary" elevation="0" block large :disabled="!valid" @click="login">ログイン</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn text color="primary" @click="rePassword">パスワードを忘れた場合</v-btn>
                            </v-col>
                        </v-row>

                    </v-form>


                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import EmailTextField from "../forms/EmailTextField.vue";
import PasswordTextField from "../forms/PasswordTextField.vue";
import axios from 'axios';
//import VueCookies from 'vue-cookies';
import Swal from 'sweetalert2';
import router from '../../router';

export default {
    name: 'Login',
    components: {
        'email-text-field': EmailTextField,
        'password-text-field': PasswordTextField,
    },
    props: {
        rules: Object
      //logined: Boolean,
    },
    //data: () => ({
    data () {
        return {
            alert: false,
            alertype: '',
            alertmsg: '',
            show_password: false,
            credentials: {},
            valid:true,
            loading:false,
            cookievalue: '',
            rules2: {
                confirmpassword:[
                    v => !!v || '入力必須',
                    v => v === this.password || '不一致',
                ],
            }
        }
    },
    //}),
    methods: {
        login() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.alert = false;
                this.alertype = 'error';
                this.alertmsg = 'ユーザー名もしくはパスワード、または両方が間違っています。';
    //console.log('this.credentials:'+JSON.stringify(this.credentials))
                axios.post('api/auth/login/', this.credentials).then(res => {
                    //console.log('res.data:'+JSON.stringify(res.data))
                    this.loading = false
                    
                    if(res.data.detail!=='' && res.data.detail!==undefined){
                        //console.log('ng:'+JSON.stringify(res.data))
                        
                        this.alert = true
                    }else{
                        
                        this.$emit('set-cookies',res.data)
                        let RoutePath = this.$cookies.get('RoutePath');

console.log('RoutePath:'+JSON.stringify(RoutePath))

                        if(RoutePath==null) RoutePath = '/home'

                        router.push(RoutePath, () => {});
                    }
                    
                    
                // eslint-disable-next-line
                }).catch(e => {
                    this.loading = false;
                    Swal.fire({
                    type: 'warning',
                    title: 'Error',
                    text: 'ユーザー名もしくはパスワード、または両方が間違っています',
                    showConfirmButton:false,
                    showCloseButton:false,
                    timer:3000
                    })
                })
            }
        },
        rePassword() {
            router.push('/repassword', () => {});
        },
    }
}
</script>