import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import Pwreset from '@/components/pages/Pwreset'
import Repassword from '@/components/pages/Repassword'
import RegisterEmail from '@/components/pages/RegisterEmail'
import Register from '@/components/pages/Register'
import Registered from '@/components/pages/Registered'
import Login from '@/components/pages/Login'
import Main from '@/components/pages/Main'
import Trecoder from '@/components/pages/Trecoder'
import Qradmin from '@/components/pages/Qradmin'
import VueCookies from 'vue-cookies';
import VueQriously from 'vue-qriously'
import VueClipboard from 'vue-clipboard2'

//import Store from "../store/index.js";

Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.use(VueQriously)
Vue.use(VueClipboard)
/*
var Auth = {
  loggedIn: false,
  login: function() { this.loggedIn = true },
  logout: function() { this.loggedIn = false }  
};
*/

const routes = [
  {
      path: '/',
      name: 'Main',
      component: Main
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { isPublic: true }
    },
    {
      path: '/register-email',
      name: 'RegisterEmail',
      component: RegisterEmail,
      meta: { isPublic: true }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: { isPublic: true }
    },
    {
      path: '/registered',
      name: 'Registered',
      component: Registered,
      meta: { isPublic: true }
    },
    {
      path: '/repassword',
      name: 'Repassword',
      component: Repassword,
      meta: { isPublic: true }
    },
    {
      path: '/pwreset',
      name: 'Pwreset',
      component: Pwreset,
      meta: { isPublic: true }
    },
    {
      path: '/home',
      name: 'home',
      badge: 0,
      badgecolors: ['success','warning','error'],
      badgecoloridx: 0,
      component: Main,
      meta: {
          name: 'ホーム',
          icon: 'mdi-home'
      },
      active: false,
      divider: false,
    },
    {
      path: '/trecos',
      name: 'trecos',
      badge: 0,
      badgecolors: ['success','warning','error'],
      badgecoloridx: 0,
      component: Trecoder,
      meta: {
          name: 'QRタイムレコーダー',
          icon: 'mdi-text-recognition'
      },
      active: false,
      divider: false,
    },
    {
      path: '/qrcreate',
      name: 'qrcreate',
      badge: 0,
      badgecolors: ['success','warning','error'],
      badgecoloridx: 0,
      component: Qradmin,
      meta: {
          name: 'Qrコード管理',
          icon: 'mdi-qrcode-edit'
      },
      active: false,
      lists: [
          {
              path:'/qrcreate',
              name :'作成発行',
              badge: 0,
              badgecolors: ['success','warning','error'],
              badgecoloridx: 0,
          },
          {
              path:'/qrread',
              name :'読込履歴',
              badge: 0,
              badgecolors: ['success','warning','error'],
              badgecoloridx: 0,
          },
      ]
    },
]

const router = new VueRouter({
  routes
})

export default router
/*
router.beforeEach((to, from, next) => {
  // isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合
  if (to.matched.some(record => !record.meta.isPublic) && !Auth.loggedIn) {
    //next({ path: '/login', query: { redirect: to.fullPath }});
    next({ path: '/login' });
  } else {
    next();
  }
});
*/