<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="300px">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      >
      <v-card 
        elevation="0"
        class="treco">
        <v-card-title>
          <v-row justify="center" no-gutters>
            <v-col></v-col>
            <v-col cols="auto">
            {{ trecoLatLng.title }}
            </v-col>
            <v-col class="text-right">
              <v-icon 
                small 
                @click="closeDialog">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        
        <v-card-text>
          <v-row justify="center">
            <v-col 
              cols="12"
              class="text-center">
              <p class="text-h5">{{ trdate }}</p>
              <p class="text-h2">{{ trtime }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        
        <v-card-actions>
          <v-btn
            color="grey lighten-2" 
            elevation="0" 
            @click="closeDialog"
          >
            キャンセル
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid" 
            color="primary" 
            elevation="0" 
            :loading="loading"
            @click="save"
          >
            checkin
          </v-btn>
          
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
//import axios from 'axios';
//import Swal from 'sweetalert2';
//import router from "../../router";
//const baseUrl = document.domain //'https://qrtimes.giverth.net/'
//import jsQR from "jsqr"

export default {
  name: "InoutDialog",
  components: {
    //'qrcode-stream':QrcodeStream,
  },
  props:{
    updateItems: Object,
    trecoLatLng:{
      type:Object,
      required:true,
    },
    /*
    zoom:{
      type:Number,
      required:true,
    },
        */
    dialog:{
      type:Boolean,
      required:true
    },
    trdate:{
      type:String,
      required:true,
    },
    trtime:{
      type:String,
      required:true,
    },
  },
  data () {
    return {
      loading:false,
      valid: true,
      editedItem: this.updateItems,
    }
  },
  //watch: {
    
  //},
  mounted() {
    
    
  },
  methods: {
    save(){
      let update = {
                    'iodate': this.editedItem.iodate,
                    'intime': this.trtime,
                    'treco': this.editedItem.treco,
                    'tcard': this.editedItem.tcard,
                    'iodatey': this.editedItem.iodatey,
                    'iodatem': this.editedItem.iodatem,
                    'iodated': this.editedItem.iodated,
                  }
        
      this.$emit('save-inout',update)
    },
    closeDialog() { 
      this.$emit('close-dialog-inout')
    },
  }
 
};
</script>
