<template>
    <v-container grid-list-md>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm8 lg4 md5>
                <v-card class="login-card">
                    <v-card-title>
                    <span class="headline">新規登録</span>
                    </v-card-title>
                    
                    <v-spacer />

                    <v-card-text>

                    <v-layout
                        row
                        fill-height
                        justify-center
                        align-center
                        v-if="loading"
                    >
                        <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                        />
                    </v-layout>

                    <v-alert
                        dense
                        text
                        :type="alertype"
                        justify="center" 
                        v-if="alert"
                        >
                        {{ alertmsg }}
                    </v-alert>
                    <v-row>
                        <v-col class="text-center">
                            <v-btn text color="primary" @click="pageLogin">ログイン画面へ</v-btn>
                        </v-col>
                    </v-row>

                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

import axios from 'axios';
//import VueCookies from 'vue-cookies';
import Swal from 'sweetalert2';
import router from '../../router';

export default {
    name: 'Register',
    props: {
        rules: Object
      //logined: Boolean,
    },
    //data: () => ({
    data () {
      return {
        alert: false,
        alertype: '',
        alertmsg: '',
        token: '',
        loading:false,
      }
    },
    //}),
    mounted() {
        this.onSave()
    },
    methods: {
        pageLogin() {
            router.push('/login', () => {});
        },
        onSave() {
            this.token = this.$route.query.token
            this.loading = true;
            this.alert = false;
            this.alertype = 'error';
            this.alertmsg = 'ユーザー認証登録に失敗しました。';

//console.log('this.credentials:'+JSON.stringify(this.credentials))
            axios.get('api/auth/email-verify/?token='+this.token).then(res => {
//console.log('res.data:'+JSON.stringify(res.data))
                if(res.data.error!=='' && res.data.error!==undefined){
                    console.log(JSON.stringify(res.data))
                    
                }else{
                    this.alertype = 'success';
                    this.alertmsg = 'ユーザー認証登録に成功しました。';
                }
                this.alert = true
                this.loading = false;

                //this.$session.start();
                //this.$session.set('token', res.data);
                //router.push('/');
            // eslint-disable-next-line
            }).catch(e => {
                this.loading = false;
                Swal.fire({
                type: 'warning',
                title: 'Error',
                text: 'ユーザー認証登録に失敗しました',
                showConfirmButton:false,
                showCloseButton:false,
                timer:3000
                })
            })
        }
    }
}
</script>