<template>
    <v-text-field
        v-model="inputedValue"
        :counter="70"
        maxlength="70"
        :rules="rules"
    />
</template>

<script>

export default {
    name: 'EmailTextField',
    props: {
        value: {
            type: String,
        },
        rules: Array,
    },
    computed: {
        inputedValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        }
    },
    
}
</script>