<template>
    <div>
      <v-card>
        Qradmin{{ $route.meta.name }}
      <v-card>   
    </div>
</template>

<script>
import axios from 'axios';
//import Swal from 'sweetalert2';
import router from "../../router";

export default {
  name: "Qradmin",
  props: {
    baseurl: String,
  },
  //data: () => ({
  data () {
    return {
      loading:false,
      credentials: {},
      access_token: '',
      refresh_token: '',
      email: '',
      username: '',
    }
  },
  //}),
  mounted() {
    

    /*
    const path = this.$route.path
    console.log(this.$route.path)
    if (path === '/register'){
      //this.$router.push({ path: '/register' })
    //} else if (path === '/trend'){
    //  this.isActive = '2'
    } else {
      this.checkLoggedIn();
    }
*/
  },
  methods: {
    getCookies() {
      const CookieValue = this.$cookies.get('CookieValue');
      //console.log(JSON.stringify(CookieValue))
      if(CookieValue){

        this.email = CookieValue.email;
        this.username= CookieValue.username;
        this.access_token = CookieValue.tokens.access;
        this.refresh_token = CookieValue.tokens.refresh;

      }

    },
    setCookies() {
      const cookies = {
        email: this.email,
        username: this.username,
        tokens:{
          access: this.access_token,
          refresh: this.refresh_token
        }
      }

      this.$emit('set-cookies',cookies)
      /*
      this.email = value.email;
      this.username= value.username;
      this.access_token = value.tokens.access;
      this.refresh_token = value.tokens.refresh;

      const cookie = { 
        username: this.username, 
        refresh_token: this.refresh_token
      }
      */

    },
    checkLoggedIn() {
        this.email = '';
        this.username = '';
        this.access_token = '';
        this.refresh_token = '';

        const CookieValue = this.$cookies.get('CookieValue');
//console.log(JSON.stringify(CookieValue))
        if(CookieValue){

          this.email = CookieValue.email;
          this.username= CookieValue.username;
          this.access_token = CookieValue.tokens.access;
          this.refresh_token = CookieValue.tokens.refresh;

        }

        //this.$session.start();
        if (this.email=='') {
            router.push('/login', () => {});
        }
    },
    checkToken() {
      
        const refreshTokens = { refresh: this.refresh_token }
        axios.post('api/auth/token/refresh/', refreshTokens).then(res => {
            console.log('res.data.access:'+JSON.stringify(res.data.access))

            if(res.data.access!=='' && res.data.access!==undefined){
              console.log('ok:'+JSON.stringify(res.data))
  
              this.access_token = res.data.access

              this.setCookies()
              
              this.loading = true
              
            }else{
              //console.log('ng:'+JSON.stringify(res.data))
              this.$emit('on-logout')
            }
                //this.$cookies.set('email', res.data.email);
                //this.$cookies.set('username', res.data.username);

            // eslint-disable-next-line
        }).catch(e => {
          console.log(e.message);
            //this.logouted();

        })
        
        /*
        const results = await axios.post(this.baseurl+'api/auth/token/refresh/', 
                  {
                  refresh: this.refresh_token
                  },
                  );
        const { data } = results;

        console.log('actions:'+data.results)
            */
    },
    logout() {
      this.$emit('check-logout',this.access_token)

        //this.$session.clear();

        /*
        axios.post('https://qrtimes.giverth.net/api/auth/logout/', 
          {
           refresh: this.refresh_token
          },
          {
           headers: { Authorization: 'JWT ' + this.access_token }
          },
          ).then(res => {
                //this.$cookies.set('email', res.data.email);
                //this.$cookies.set('username', res.data.username);
console.log(JSON.stringify(res.data))
                this.$cookies.remove('CookieValue');
                router.push("/login");
            // eslint-disable-next-line
            }).catch(e => {
              console.log(e.message);
                this.$cookies.remove('CookieValue');
                router.push("/login");

            })
*/
    }
  }
};
</script>