<template>
  <v-row justify="center">
    <v-col
      cols="12">
      <v-row no-gutters>
        <v-col 
          cols="12"
          sm="12"
          md="8"
          lg="8">
          <v-text-field
            dense
            v-model="inputaddress"
            label="住所"
            maxlength="100"
            clearable
          />
        </v-col>
        <v-col 
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="text-center">

          <v-row 
            justify="center" 
            no-gutters>
            <v-col
              cols="4"
              sm="6"
            >
              <v-btn
                text
                color="primary"
                @click="setJyusyo"
              >
                <v-icon>mdi-magnify</v-icon>検索
              </v-btn>
            </v-col>
            <v-col
              cols="8"
              sm="6"
            >
              <v-btn
                text
                color="primary"
                @click="rePoint"
              >
                <v-icon>mdi-map-marker-account</v-icon>現在地
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
    </v-col>

    <v-col 
      cols="12"
      sm="12"
      md="6"
      lg="6"
      class="my-0">
      <div ref="map" class="googleMap"></div>
      <label class="mt-2 text-left">{{ newLatLng.address }}</label>
    </v-col>
    <v-col 
      cols="12"
      sm="12"
      md="6"
      lg="6">
      
      <p class="text-left">読込可エリア（半径 {{ newLatLng.radius }}m）</p>
      <v-row>
        <v-col class="mt-0 pr-4">
          <v-slider
            v-model="radius"
            class="align-center"
            :max="max"
            :min="min"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="radius"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      
    </v-col>
  </v-row>
</template>

<script>
//import axios from 'axios';
//import Swal from 'sweetalert2';
//import router from "../../router";
//const baseUrl = document.domain //'https://qrtimes.giverth.net/'
//import jsQR from "jsqr"

export default {
  name: "Googlemap",
  components: {
    //'qrcode-stream':QrcodeStream,
  },
  props:{
    myLatLng:{
      type:Object,
      required:true,
    },
    zoom:{
      type:Number,
      required:true,
    },
    init:{
      type:Boolean,
      required:true
    }
  },
  data () {
    return {
      loading:false,
      map:null,
      marker:null,
      circle:null,
      position:null,
      newLatLng:{},
      radius: 10,
      inputaddress: '',
      min: 1,
      max: 100,
      initPoint: false,
    }
  },
  watch: {
    init(val){
      console.log('init:'+val)
      this.initCurrentPosition()
    },
    radius(val){
      this.newLatLng.radius = val
      this.setCircle()
    },
  },
  mounted() {
    let script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCB4OBvNqBWCDLJeKjcjMi0azdiPpwA8ns';
    script.async = true;
    document.head.appendChild(script);
    
    this.newLatLng = this.myLatLng
    
    this.initCurrentPosition()
    
  },
  methods: {
    async initCurrentPosition() {
      this.inputaddress = ''

      try {
        
        const position = await this.getCurrentPosition()
        this.newLatLng.lat = position.coords.latitude
        this.newLatLng.lng = position.coords.longitude
        
        this.initMap()
//console.log('this.newLatLng:'+JSON.stringify(this.newLatLng))

      } catch (error) {
        alert(error)
      }
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        const message = [
          '現在位置が取得できませんでした',
          '位置情報の利用が許可されていません',
          '現在位置が取得できませんでした',
          'タイムアウトになりました',
          '現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。'
        ]
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            response => {
              resolve(response)
            },
            error => {
              reject(message[error.code])
            },
            {
              enableHighAccuracy: false,
              timeout: 5000,
              maximumAge: 0
            }
          )
        } else {
          reject(message[4])
        }
      })
    },
    initMap(){       
      
      let timer = setInterval(() => {
        if(window.google){
          //console.log('this.myLatLng.lat:'+this.myLatLng.lat)
          //console.log('this.myLatLng.lng:'+this.myLatLng.lng)
          //console.log('this.myLatLng:'+JSON.stringify(this.myLatLng))

          clearInterval(timer);
          /*
          const map = new window.google.maps.Map(this.$refs.map, {
            center: this.myLatLng,
            zoom: this.zoom
          });

          //リスナーの登録
          map.addListener('click',(mapsMouseEvent)=>{
            return this.clickOnMap(mapsMouseEvent);
          });

          this.map = map
  */
          
          this.setMap()

          
          this.getJyusyo()

          this.setMarker()
          
          this.radius = this.newLatLng.radius
//console.log('this.radius:'+this.radius)
          this.setCircle()
          
          //this.setJyusyo()
        }
      },500)

    },
    setMap(){
      let latlng ={}
      latlng.lat = this.newLatLng.lat
      latlng.lng = this.newLatLng.lng

      const map = new window.google.maps.Map(this.$refs.map, {
        center: latlng,
        zoom: this.zoom
      });

      //リスナーの登録
      map.addListener('click',(mapsMouseEvent)=>{
        return this.clickOnMap(mapsMouseEvent);
      });

      this.map = map
    },
    clickOnMap(mapEvent){
      let latlng = JSON.parse(JSON.stringify(mapEvent.latLng))
      
      this.position = latlng.toString();

      this.newLatLng.lat = latlng.lat
      this.newLatLng.lng = latlng.lng

      this.getJyusyo()
      this.radius = 0
      this.setMarker()
      this.radius = this.newLatLng.radius
      this.setCircle()
      //setTimeout(() => {
      this.getDistance()
      //}, 500);
      

    },
    setMarker(){
      let latlng ={}
      latlng.lat = this.newLatLng.lat
      latlng.lng = this.newLatLng.lng

      if(this.marker!==null) this.marker.setMap(null);
      this.marker = new window.google.maps.Marker({position:latlng,map:this.map})
    },
    setCircle(){
      let latlng ={}
      latlng.lat = this.newLatLng.lat
      latlng.lng = this.newLatLng.lng
//console.log('this.radius:'+JSON.stringify(this.radius))
          
      if(this.circle!==null) this.circle.setMap(null);
      this.circle = new window.google.maps.Circle({
          center: latlng,       // 中心点(google.maps.LatLng)
          fillColor: '#ff0000',   // 塗りつぶし色
          fillOpacity: 0.5,       // 塗りつぶし透過度（0: 透明 ⇔ 1:不透明）
          map: this.map,             // 表示させる地図（google.maps.Map）
          radius: this.radius,          // 半径（ｍ）
          strokeColor: '#ff0000', // 外周色
          strokeOpacity: 1,       // 外周透過度（0: 透明 ⇔ 1:不透明）
          strokeWeight: 1         // 外周太さ（ピクセル）
        });
      this.setLatLng(this.newLatLng)
    },
    setLatLng(newLatLng){
      console.log('newLatLng:'+JSON.stringify(newLatLng))

      this.$emit('set-latlng',newLatLng)
    },
    getDistance(){
      this.$emit('get-distance',this.newLatLng)
    },
    getJyusyo(){
      let latlng ={}
      latlng.lat = this.newLatLng.lat
      latlng.lng = this.newLatLng.lng

      const self = this
      //Google Mapsで住所を取得
      let geocoder = new window.google.maps.Geocoder();
      //let latlng = new window.google.maps.LatLng(lat, lng);
      geocoder.geocode({'latLng': latlng}, function(results, status) {

      if (status == window.google.maps.GeocoderStatus.OK) {
        self.newLatLng.address = results[0].formatted_address
        self.newLatLng.address = self.newLatLng.address.replace('日本、','')
        self.inputaddress = self.newLatLng.address
        //self.setLatLng(self.newLatLng)
        
        //document.getElementById('address').innerHTML = results[0].formatted_address;
      }else{
        alert("エラー" + status);
      }
      });
    },
    setJyusyo(){
      //const title = '住所'
      //表示したい住所を取得
      //const inputAddress = document.getElementById('address').textContent;
      //const inputAddress = '東京都港区西麻布2'
      console.log('this.inputaddress:'+this.inputaddress)
      //ジオコーディングのインスタンスの生成
      if(this.inputaddress=='' || this.inputaddress==null) return null

      const geocoder = new window.google.maps.Geocoder();

      const self = this
      //geocoder.geocode() にアドレスを渡して、コールバック関数を記述して処理
      geocoder.geocode({ address: this.inputaddress }, function(results, status){
      //ステータスが OK で results[0] が存在すれば、地図を生成
      
        if (status === 'OK' && results[0]){
          let latlng = JSON.parse(JSON.stringify(results[0].geometry.location))

          self.newLatLng.lat = latlng.lat
          self.newLatLng.lng = latlng.lng
          
console.log('latlng.lat:'+JSON.stringify(latlng.lat))

          self.initMap()

          //self.setMarker()
        
          //self.setCircle()
        
          //self.getJyusyo()
          /*
          //マップのインスタンスを変数に代入
          const map = new window.google.maps.Map(self.$refs.map, {
          //results[0].geometry.location に緯度・経度のオブジェクトが入っている
            center: self.newLatLng,
            zoom: 15
          });

          self.map = map
          
          //this.setMarker(this.newLatLng)
          //this.setCircle(this.newLatLng)
          //マーカーの生成
          const marker = new window.google.maps.Marker({
            position: self.newLatLng,
            map: map,
          });
          //取得した座標の生成
          const latlng = new window.google.maps.LatLng(self.newLatLng.lat(), self.newLatLng.lng());
          //情報ウィンドウに表示するコンテンツを作成
          const content = '<div id="map_content"><p>' + title + '<br/>' + inputAddress + '<br/><a href="https://maps.google.co.jp/maps?q=' + latlng + '&iwloc=J" target="_blank" rel="noopener noreferrer">Googleマップで見る</a></p></div>';
          //情報ウィンドウのインスタンスを生成
          var infowindow = new window.google.maps.InfoWindow({
            content: content,
          });
          //marker をクリックすると情報ウィンドウを表示(リスナーの登録）
          window.google.maps.event.addListener(marker, 'click', function() {
            //第2引数にマーカーを指定して紐付け
            infowindow.open(map, marker);
          });
*/
          
        } else {
        //ステータスが OK 以外の場合や results[0] が存在しなければ、アラートを表示して処理を中断
          alert("住所から位置の取得ができませんでした。: " + status);
          return;
        }
      });
    },
    rePoint(){
      this.initCurrentPosition()
      this.initPoint = !this.initPoint
    },
    /*
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        const message = [
          '現在位置が取得できませんでした',
          '位置情報の利用が許可されていません',
          '現在位置が取得できませんでした',
          'タイムアウトになりました',
          '現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。'
        ]
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            response => {
              resolve(response)
            },
            error => {
              reject(message[error.code])
            },
            {
              enableHighAccuracy: false,
              timeout: 5000,
              maximumAge: 0
            }
          )
        } else {
          reject(message[4])
        }
      })
    },
 */
  }
 
};
</script>

<style>
      .googleMap {
        height: 200pt;
        width: 100%;
        z-index: 2 !important;
      }
</style>
