<template>

    <v-container fluid v-if="!loading"> <!-- fluidを付けることで左右いっぱいにコンテナが広がります -->
        <!-- fill-heightを付けるとここに書いた要素は上下中央寄せで表示されます -->
        <v-row dense>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
        >
          <v-card>
            <v-card
              dark
              flat
            >
              <v-btn
                absolute
                bottom
                color="primary"
                right
                fab
                @click="openCamera"
                >
                  <v-icon>mdi-qrcode-scan</v-icon>
              </v-btn>
              
              <v-img
                src="https://cdn.vuetifyjs.com/images/cards/forest.jpg"
                gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)"
              >
                <v-container class="fill-height">
                  <v-row align="center">
                    <strong class="text-h1 font-weight-regular mr-6">8</strong>
                    <v-row justify="end">
                      <div class="text-h5 font-weight-light">
                        Monday
                      </div>
                      <div class="text-uppercase font-weight-light">
                        February 2015
                      </div>
                    </v-row>
                  </v-row>
                </v-container>
              </v-img>
            </v-card>
            <v-card-text class="py-0">
                <v-timeline
                  align-top
                  dense
                >
                  <v-timeline-item
                    color="pink"
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong>5pm</strong>
                      </v-col>
                      <v-col>
                        <strong>New Icon</strong>
                        <div class="text-caption">
                          Mobile App
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    color="teal lighten-3"
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong>3-4pm</strong>
                      </v-col>
                      <v-col>
                        <strong>Design Stand Up</strong>
                        <div class="text-caption mb-2">
                          Hangouts
                        </div>
                        <v-avatar>
                          <v-img
                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                          ></v-img>
                        </v-avatar>
                        <v-avatar>
                          <v-img
                            src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
                          ></v-img>
                        </v-avatar>
                        <v-avatar>
                          <v-img
                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"
                          ></v-img>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    color="pink"
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong>12pm</strong>
                      </v-col>
                      <v-col>
                        <strong>Lunch break</strong>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    color="teal lighten-3"
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong>9-11am</strong>
                      </v-col>
                      <v-col>
                        <strong>Finish Home Screen</strong>
                        <div class="text-caption">
                          Web App
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-bookmark</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
        >
          <v-card 
            class="mx-auto"
          >
            <v-card-title>
              <v-icon
                large
                left
              >
                mdi-credit-card-clock-outline 
              </v-icon>
              <span class="text-h6 font-weight-light">カード</span>
            </v-card-title>

            <v-list class="transparent">
              <v-list-item
                v-for="item in tcards"
                :key="item.day"
              >
                <v-list-item-title>{{ item.last_name }}{{ item.first_name }}</v-list-item-title>

                <v-list-item-subtitle class="text-right">
                  {{ setDtString(item.created_at) }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-bookmark</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          >
          <v-card
            class="treco mx-auto"
            dark
          >
            <v-card-title>
              <v-icon
                large
                left
              >
                mdi-text-recognition
              </v-icon>
              <span class="text-h6 font-weight-light">QRタイムレコーダー</span>
            </v-card-title>

            <v-card-text class="text-h5 font-weight-bold">
              "タイムレコーダーのQRコードを作成できます"
            </v-card-text>

            <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    alt=""
                    src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title></v-list-item-title>
                </v-list-item-content>

                <v-row
                  align="center"
                  justify="end"
                >
                  <v-icon class="mr-1">
                    mdi-heart
                  </v-icon>
                  <span class="subheading mr-2">256</span>
                  <span class="mr-1">·</span>
                  <v-icon class="mr-1">
                    mdi-share-variant
                  </v-icon>
                  <span class="subheading">45</span>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
        
      <v-data-table
            :headers="headers0"
            :items="items0"
            :items-per-page="10"
            :page.sync="page0"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 30, 50, -1],
              'items-per-page-text':'ページ表示数'
            }"
            :loading="loading"
            multi-sort
            class="elevation-0 mt-2"
          >

            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            
<!---->
            <template v-if="items0.length==0" v-slot:no-data>
              データがありません
            </template>

            <template v-slot:[`item.trecoderqrcds`]="{ item }">
                <span>{{ setString(item.trecoderqrcds) }}</span>
            </template>

            <template v-slot:[`item.tcards`]="{ item }">
                <span>{{ setString(item.tcards) }}</span>
            </template>

            <template v-slot:[`item.inouts`]="{ item }">
                <span>{{ setString(item.inouts) }}</span>
            </template>

            <template v-slot:[`item.updated_at`]="{ item }">
                <span>{{ setDtString(item.updated_at) }}</span>
            </template>
            

          </v-data-table>
<!--
        <v-btn
          fab
          fixed 
          bottom
          right
          color="primary"
          style="bottom: 36px"
          @click="openCamera"
        >
        
          <v-icon>mdi-qrcode-scan</v-icon>
        </v-btn>
-->  
        <tcard-dialog 
          :dialog="dialogTcard" 
          :rules="rules"
          :updateItems="updateItems"
          @save-tcard="saveTcard"
          @close-dialog-tcard="closeDialogTcard" />

        <inout-dialog 
          :dialog="dialogInout" 
          :trecoLatLng="trecoLatLng"
          :trdate="trdate"
          :trtime="trtime"
          :updateItems="updateItems"
          @save-inout="saveInout"
          @close-dialog-inout="closeDialogInout" />
<!--
          <v-dialog
            v-model="dialogInout"
            persistent
            max-width="300px">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              >
              <v-card 
                elevation="0"
                class="">
                <v-card-title>
                  <v-row justify="center" no-gutters>
                    <v-col></v-col>
                    <v-col cols="auto">
                    {{ trecoLatLng.title }}
                    </v-col>
                    <v-col class="text-right">
                      <v-icon 
                        small 
                        @click="closedialogInout">
                        mdi-close
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-title>
                
                <v-card-text>
                  <v-row justify="center">
                    <v-col 
                      cols="12"
                      class="text-center">
                      <p class="text-h5">{{ trdate }}</p>
                      <p class="text-h2">{{ trtime }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
                
                <v-card-actions>
                  <v-col
                    cols="12"
                    class="mt-2 text-center"
                    >
                    
                    <v-btn
                      block
                      :disabled="!valid" 
                      color="primary" 
                      elevation="0" 
                      :loading="loading"
                      @click="saveCheck"
                    >
                      checkin
                    </v-btn>
                  </v-col>
                  
                </v-card-actions>
      
              </v-card>
            </v-form>
          </v-dialog>
 -->

        <v-bottom-sheet 
          v-model="camerasheet">
          <!--
          <v-sheet
            class="text-center"
            fluid
          >
          -->
          <v-card 
            flat
            class="pa-2"
            >

            <v-card-title class="title pa-0">
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col class="my-2" cols="8">
                  <label>QRリーダー</label>
                </v-col>
                <v-col cols="2">
                  <v-icon 
                    small
                    @click="closeCamera">
                    mdi-close
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-title>  
              
            <v-col
                class="py-3 text-center"
                cols="12"
              >
              <video
                :width="width"
                :height="height"
                autoplay
                playsinline/>
                
              <!-- <div :style="{ width: width + 'px', height: height+ 'px' }"> -->
                      <!-- canvasなしでも仮想DOMを作成して描画 -->
                      <!-- <canvas
                        :width="width"
                        :height="height"
                      /> -->
              <!-- </div> -->
            </v-col>
            <v-col
                class="py-3 text-center"
                cols="12"
              >
              <v-btn 
                  class="mb-6"
                  text color="primary"
                  @click="changeFront">
                  <v-icon>mdi-camera-retake-outline</v-icon> 
                </v-btn>
            </v-col>

          </v-card>
        <!--  </v-sheet> -->
        </v-bottom-sheet>
        <v-form :action="qrurl" method="POST" ref="form" style="display: none;">
          <input :value="qrcd" type="hidden" name="cd">
          <input :value="qrtype" type="hidden" name="type">
        </v-form>
    </v-container>
    
</template>

<script>
import TcardDialog from "../comps/TcardDialog.vue";
import InoutDialog from "../comps/InoutDialog.vue";
import axios from 'axios';
import Swal from 'sweetalert2';
import router from "../../router";
//const baseUrl = document.domain //'https://qrtimes.giverth.net/'
import jsQR from "jsqr"

const { point } = require('@turf/helpers')
const distance = require('@turf/distance').default

export default {
  name: "Main",
  components: {
    'tcard-dialog':TcardDialog,
    'inout-dialog':InoutDialog,
  },
  props: {
    rules: Object,
  },
  //data: () => ({
  data () {
    return {
      loading:false,
      qrcd: null,
      qrtype: null,
      credentials: {},
      access_token: '',
      refresh_token: '',
      email: '',
      username: '',
      camerasheet: false,
      srcObject: "",
      width: 250,
      height: 250,
      qrurl: null,
      error: '',
      is_front: true,
      facingmode: "user",
      repeat: null,
      videoStream: null,
      opencameratime: 0,
      map:null,
      myLatLng: {},
      trecoLatLng:{},
      latlng: null,
      mapOptions: null,
      label: null,
      initPoint: false,
      trdate: '',
      trtime: '',
      trweek: ['（日）', '（月）', '（火）', '（水）', '（木）',  '（金）', '（土）'] ,
      valid: true,
      dialogInout: false,
      dialogTcard: false,
      updateItems: {},
      tcards: {},
      tcardtitle: '',
      inouts: {},
      treco_tcard_inout: {},
      nowymd: '',
      nowy: 2022,
      nowm: 1,
      page0: 1,
      items0: [],
      headers0: [
        { align: 'left',   sortable: true,  groupable: false, text: 'タイトル',     value: 'title' },
        { align: 'left',   sortable: true,  groupable: false, text: 'trecoderqrcds',     value: 'trecoderqrcds' },
        { align: 'left',   sortable: true,  groupable: false, text: 'tcards',     value: 'tcards' },
        { align: 'center', sortable: false, groupable: false, text: 'inouts',  value: 'inouts' },
        { align: 'left',   sortable: true,  groupable: false, text: '更新日時',     value: 'updated_at' },
      ],
    }
  },
  computed: {
    //qrurl(val){
    //  this.isReadQr = false
    //  if(val!==null) this.isReadQr = true
    //},
    isReadQr(){
      return Boolean(this.qrurl);
    }
  },
  
  watch: {
    camerasheet(val){
      //alert(val)
      if(!val) this.cameraStop()
      
    },
    opencameratime(val){
      if(val>60) this.closeCamera()
    }
  },
  /*
  async beforeRouteUpdate(to, from, next) {
    // ブラウザバック時、 router.push 時にここに来る
    //await this.getData(to.query);
    console.log('beforeRouteUpdate:'+JSON.stringify(to.query))
    console.log('from:'+JSON.stringify(from))
    //window.location.href = this.qrurl

    next();
  },
  */
  //}),
  //created() {

  //},
  mounted() {
    this.getCookies()
    this.checkToken()

    this.setNowymd()
    
    this.qrcd = this.$route.query.cd
    this.qrtype = this.$route.query.type

    this.getTrecoTcardInout()
//    this.getInouts()

    setInterval(this.updateTime, 1000)
    //this.$nextTick(() => {
      
    //})
    /*
    const path = this.$route.path
    
    if (path === '/register'){
      //this.$router.push({ path: '/register' })
    //} else if (path === '/trend'){
    //  this.isActive = '2'
    } else {
      this.checkLoggedIn();
    }
*/
  },
  methods: {
    async initCurrentPosition() {
      try {
        
        const position = await this.getCurrentPosition()
        this.myLatLng.lat = position.coords.latitude
        this.myLatLng.lng = position.coords.longitude
        //const accuracy = position.coords.accuracy;

//console.log('this.myLatLng.accuracy:'+JSON.stringify(accuracy))

        const from = point([Number(this.myLatLng.lng),Number(this.myLatLng.lat)]); //[経度, 緯度] 東京駅
        const to = point([Number(this.trecoLatLng.lng),Number(this.trecoLatLng.lat)]); //[経度, 緯度] 名古屋駅
        const options = { units: 'kilometers' }; // degrees, radians, miles, kilometersが指定可能

        this.myLatLng.radius = distance(from, to, options);
        this.myLatLng.radius = this.myLatLng.radius*1000;

//console.log('this.trecoLatLng:'+JSON.stringify(this.trecoLatLng))
        if(this.trecoLatLng.radius<=this.myLatLng.radius){
          alert('読込エリア外です')
        }else{
          this.openDialogInout()
        }

      } catch (error) {
        alert(error)
      }
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        const message = [
          '現在位置が取得できませんでした',
          '位置情報の利用が許可されていません',
          '現在位置が取得できませんでした',
          'タイムアウトになりました',
          '現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。'
        ]
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            response => {
              resolve(response)
            },
            error => {
              reject(message[error.code])
            },
            {
              enableHighAccuracy: false,
              timeout: 5000,
              maximumAge: 0
            }
          )
        } else {
          reject(message[4])
        }
      })
    },
    getDistance(){
    
      const from = point([Number(this.myLatLng.lng),Number(this.myLatLng.lat)]); //[経度, 緯度] 東京駅
      const to = point([Number(this.trecoLatLng.lng),Number(this.trecoLatLng.lat)]); //[経度, 緯度] 名古屋駅
      const options = { units: 'kilometers' }; // degrees, radians, miles, kilometersが指定可能

      const result = distance(from, to, options);
      console.log(result*1000) // 267.50358367757696
      return result*1000
    },
    updateTime() { 

      //現在の日付・時刻を取得 
      let currentdate = new Date()
      
      // 現在の時刻
      //this.trtime = this.zeroPadding(currentdate.getHours(), 2) + ':' + this.zeroPadding(currentdate.getMinutes(), 2) + ':' + this.zeroPadding(currentdate.getSeconds(), 2)
      this.trtime = this.zeroPadding(currentdate.getHours(), 2) + ':' + this.zeroPadding(currentdate.getMinutes(), 2)

      // 現在の年月日
      this.trdate = this.zeroPadding(currentdate.getFullYear(), 4) + '年' + this.zeroPadding(currentdate.getMonth() + 1, 2) + '月' + this.zeroPadding(currentdate.getDate(), 2) + '日' + this.trweek[currentdate.getDay()]
    },
    setString(item){
      return JSON.stringify(item)
    },
    setDtString(item){
      let date = Date.parse(item);
      //let date = item.replace(/-/g, '/') 
      date = new Date(date)
      date = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' +('0' + date.getDate()).slice(-2) + ' ' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
      
      return date
    },
    zeroPadding(num, len) {
      let zero = '';

     // 0の文字列を作成
      for(var i = 0; i < len; i++) {
        zero += '0';
     }

     // zeroの文字列と、数字を結合し、後ろ２文字を返す
      return (zero + num).slice(-len);
    },
    setNowymd(){
      let currentdate = new Date()
      this.nowy = currentdate.getFullYear()
      this.nowm = currentdate.getMonth() + 1
      
      this.updateItems.iodatey = this.nowy
      this.updateItems.iodatem = this.nowm
      this.updateItems.iodated = currentdate.getDate()
      this.updateItems.iodate = this.zeroPadding(this.updateItems.iodatey, 4) + '/' + this.zeroPadding(this.updateItems.iodatem, 2) + '/' + this.zeroPadding(this.updateItems.iodated, 2)
      this.nowymd = this.zeroPadding(this.updateItems.iodatey, 4) + this.zeroPadding(this.updateItems.iodatem, 2) + this.zeroPadding(this.updateItems.iodated, 2)
    },
    createAxiosInstance(){
        // axios.create でいきなり axios を呼んだ時に使われる通信部(AxiosInstance)がインスタンス化される
        const axiosInstance = axios.create({
            // この第一引数オブジェクトで設定を定義
            
            // axios で通信する時の URL の頭を決める。大体ドメインとAPI用URL接頭辞
            baseURL: process.env.MIX_BASE_APP_URL,
        });
        
        // interceptors.request.use で送信時に引数に入れた関数が動作する
        // 引数で渡ってくるのは axios の設定(送信先や通信方式も持つ今まさに通信を実行しようとしている設定)で、返り値が通信時に実際に使われる axios の設定になる
        axiosInstance.interceptors.request.use((request)=>{
            // もしヘッダーに API トークンを記述するならば
            //request.headers['Content-Type'] = 'application/json'
            //request.headers['X-Requested-With'] = 'XMLHttpRequest'
            request.headers['Authorization'] = 'JWT ' + this.access_token
            // もし URL に APIトークンを増やすならば
            //request.params = request.params || {};
            //request.params.apiToken = getApiToken();
            
            // リクエスト内容を見るならば
            //console.dir(request)
            
            return request;
        })
    
        // interceptors.response.use で返信時に引数に入れた関数が動作する
        axiosInstance.interceptors.response.use(
            (response)=>response, // 第一引数は通信成功時処理。受けた内容をそのまま通過
            (error)=>{ // 第二引数は通信失敗時処理
                this.loading = false
                // 通信エラーの内容全体をインデント付きのJSONにして alert 表示
                // これだけだととても見難いので適宜プロジェクトに合わせて必要な情報だけ取る処理にした方がベター
                //alert(JSON.stringify(error, ' ', 4))
                Swal.fire({
                type: 'warning',
                title: 'Error',
                text: '処理に失敗しました:'+error.message,
                showConfirmButton:false,
                showCloseButton:false,
                timer:3000
                })
            }
        )
    
        // interceptor で共通処理を追加した通信機能を返す。
        return axiosInstance;
    },
    saveCheck(){
      /*
        const BaseRepository = this.createAxiosInstance();
        console.log('this.editedItem.is_latlng:'+JSON.stringify(this.editedItem.is_latlng))
        let update = {
                      'type': 'TIME_RECORDER',
                      'title': this.editedItem.title,
                      'is_latlng': this.editedItem.is_latlng,
                    }
        
        if(this.editedItem.description!=='') update['description'] = this.editedItem.description
console.log('this.newLatLng:'+JSON.stringify(this.newLatLng))
        if(this.editedItem.is_latlng){
          update['lat'] = this.newLatLng.lat
          update['lng'] = this.newLatLng.lng
          update['radius'] = this.newLatLng.radius
          update['address'] = this.newLatLng.address
          console.log('update:'+JSON.stringify(update))
        }else{
          update['lat'] = null
          update['lng'] = null
          update['radius'] = null
          update['address'] = null
        }

        if(this.dialogtype=='new'){

            BaseRepository.post('api/trecos/', update).then(response => {
                const data = response.data;
                console.log('data:'+JSON.stringify(data))
                
                if(data.id!==''){                 
                    this.editedItem = data
                    this.createTrecoqrcds()
                }
                
                return data;
            });
            
        }else{

            BaseRepository.patch('api/treco/'+this.editedItem.id, update).then(response => {
                const data = response.data;
                if(data.id!==''){
                    this.editedItem = data
                    this.qrcd = this.editedItem.trecoderqrcd[0].id
                    this.qrurl = this.setQrurl(this.qrcd)
                    this.getTrecoders()
                }
                
                console.log('data:'+JSON.stringify(data))
                return data;
            });
        }
        */
    },
    closeDialogTcard(){
      this.dialogTcard = false
    },
    openDialogInout(){
      
      console.log('this.updateItems:'+JSON.stringify(this.updateItems))

      this.dialogInout = true
    },
    closeDialogInout(){
      this.dialogInout = false
    },
    saveTcard(update){
        const BaseRepository = this.createAxiosInstance();
        
        BaseRepository.post('api/tcards/', update).then(response => {
            const data = response.data;
            console.log('data:'+JSON.stringify(data))
            this.dialogTcard = false

            if(this.qrcd!=null) this.getTrecoders(this.updateItems)

            return data;
        });
    },
    saveInout(update){
        const BaseRepository = this.createAxiosInstance();

        console.log('saveInout(update):'+JSON.stringify(update))
        
        BaseRepository.post('api/inouts/', update).then(response => {
            const data = response.data;
            console.log('data:'+JSON.stringify(data))
            this.dialogTcard = false


            return data;
        });
    },
    getTrecoTcardInout(){
      this.loading = true
        const BaseRepository = this.createAxiosInstance();
        console.log('this.nowymd:'+JSON.stringify(this.nowymd))
        BaseRepository.get('api/treco_tcard_inout/?iodatey='+this.nowy+'&iodatem='+this.nowm).then(response => {
            const data = response.data;
            console.log('response.data:'+JSON.stringify(response.data))
            this.loading = false;
            if(data!==''){
                this.items0 = data
                this.treco_tcard_inout = data[0]
                console.log('this.treco_tcard_inout:'+JSON.stringify(this.treco_tcard_inout))
            
            }
            
            if(this.treco_tcard_inout==undefined || this.qrcd!=null) this.getTcards()
            
            return data;
        });

    },
    getInouts() {
        //console.log(JSON.stringify(this.access_token))
        this.loading = true
        const BaseRepository = this.createAxiosInstance();
        
        BaseRepository.get('api/inouts/?'+this.nowymd).then(response => {
            const data = response.data;
            this.loading = false;
            if(data!==''){
                this.inouts = data.results
                console.log('this.inouts:'+JSON.stringify(this.inouts))
            
            }
            
            return data;
        });

/*
        setTimeout(() => {
        
        }, 1000);
*/        

    },
    getTcards() {
        //console.log(JSON.stringify(this.access_token))
        this.loading = true
        const BaseRepository = this.createAxiosInstance();
        
        BaseRepository.get('api/tcards/').then(response => {
            const data = response.data;
            this.loading = false;
            if(data!==''){
                this.tcards = data.results
                this.tcardtitle = this.tcards[1].last_name + this.tcards[1].first_name
                if(this.qrcd!=null) this.getTrecoqrcd()
                console.log('this.tcards:'+JSON.stringify(this.tcards))
                
            }
            
            return data;
        });

/*
        setTimeout(() => {
        
        }, 1000);
*/        

    },
    getTrecoders(update) {
        //console.log(JSON.stringify(this.access_token))
        this.loading = true
        const BaseRepository = this.createAxiosInstance();
        
        BaseRepository.get('api/treco/' + update.treco).then(response => {
            const data = response.data;
            this.loading = false;
            //console.log('data:'+JSON.stringify(data))
            if(data!==undefined){
              console.log('data:'+JSON.stringify(data))
              this.trecoLatLng.title = data.title
              //this.trecoLatLng.tcard = update.tcard

              if(data.is_latlng){
                this.trecoLatLng.lat = data.lat
                this.trecoLatLng.lng = data.lng
                this.trecoLatLng.radius = data.radius
                
                this.initCurrentPosition()
              }else{
                this.openDialogInout()
              }
                /*
                if(data.detail==undefined){
                    
                }else{
                    //this.createTrecoqrcds()
                }
                */
            }
            return data;
        });

        setTimeout(() => {
        
            axios.get('api/trecos/', 
              {
                headers: { 
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'XMLHttpRequest',
                  'Authorization': 'JWT ' + this.access_token
                }
              },
            ).then(res => {
              this.loading = false
              //console.log('res.data:'+JSON.stringify(res.data))
              this.items0 = res.data.results

              //if(this.dialogInout) this.closeDialogInout()
                  // eslint-disable-next-line
            }).catch(error => {
              this.loading = false
              console.log('error:'+JSON.stringify(error));
              this.onLogout()
              //this.onLogout();
      //this.errorHandler(res, error)
            })
            
        }, 1000);
       

    },
    getTrecoqrcd(){
        this.loading = true;
        const BaseRepository = this.createAxiosInstance();
        //setTimeout(() => {
          BaseRepository.get('api/trecoqrcd/' + this.qrcd).then(response => {
              const data = response.data;
              this.loading = false;
              console.log('getTrecoqrcd:'+JSON.stringify(data))
              if(data.treco!==undefined){
                this.updateItems.treco = data.treco
                let tcards = 0
                for(var item of this.tcards.map(val => { return val })){
                  if(item.treco==this.updateItems.treco && item.is_active){
                    this.updateItems.tcard = item.id
                    tcards += 1
                  }

                }
                
                console.log('this.updateItems:'+JSON.stringify(this.updateItems))

                //if(tcards==0) this.saveTcard()
                if(tcards==0){
                  this.dialogTcard = true
                }else{
                  this.getTrecoders(this.updateItems)
                }
                //this.getTrecoders(data.treco)
                  /*
                  if(data.detail==undefined){
                      
                  }else{
                      //this.createTrecoqrcds()
                  }
                  */
              }
              return data;
          });
        //}, 1000);
    },
    openCamera(){
      this.camerasheet = true
      this.cameraStart()
    },
    closeCamera(){
      
      this.camerasheet = false
      this.cameraStop()

    },
    changeFront(){
      this.cameraStop()
      this.is_front = !this.is_front
      this.setCamerafacingMode()
      this.cameraStart()
    },
    setCamerafacingMode(){
      if(this.is_front){
        this.facingmode = "user"
      }else{
        this.facingmode = { exact: "environment" }
      }
      
      //"user":{ exact: "environment" }
    },
    cameraStop(){
      this.opencameratime = 0
      clearInterval(this.repeat)
      document.querySelector("video").srcObject = null
      this.videoStream.getVideoTracks().forEach((track) => {
          track.stop();
      })
    },
    cameraStart(){
      this.qrurl = null
      //let videoId = $('#video').val();
      //console.log('this.constraints:'+JSON.stringify(this.constraints))
      const p = navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          width: { min: this.width, ideal: this.width*2, max: this.width*3 },
          height: { min: this.height, ideal: this.height*2, max: this.width*3 },
          facingMode: this.facingmode,
          //frameRate: { ideal: 5, max: 15 }
        }
      });

      const self = this
      p.then(function(mediaStream) {
        self.videoStream = mediaStream;
        document.querySelector("video").srcObject = mediaStream;
        setTimeout(() => {
          self.readImage()
        }, 2000);
      })
      .catch( (err) => {
        console.log(`${err.name}: ${err.message}`);
        this.changeFront()
        alert("カメラとの接続時にエラーが発生しました");
      });

      /*
      if(document.querySelector("video").srcObject !== null){
        setTimeout(() => {
            this.readImage()
        }, 3000);
      }
      */
    },
    readImage() {
      const video = document.querySelector("video");
      const canv = document.createElement("canvas");
      canv.height = this.height;
      canv.width = this.width;
//alert('readImage')
      const context = canv.getContext("2d");

      this.repeat = setInterval(() => {
        //console.log("search .....");
        this.opencameratime += 0.5
        context.drawImage(video, 0, 0, this.width, this.height)

        const imageData = context.getImageData(0, 0, this.width, this.height)
        const code = jsQR(imageData.data, imageData.width, imageData.height)
        if (code) {
          //console.log("Found QR code", code, code.data);
          this.qrurl = code.data;
          //document.location.href = code.data;
          //window.location.href = code.data

/*          
          const url = new URL(code.data)
          const urlParams = new URLSearchParams(url.search);
          console.log("urlParams:"+urlParams);
          this.qrcd = urlParams.get("cd");
          this.qrtype = urlParams.get("type");

          setTimeout(() => {
            router.push({ path: '/home' , query :{ cd: this.qrcd, type: this.qrtype }}, () => {})
            this.closeCamera()
          }, 500);
*/

          setTimeout(() => {
            this.$refs.form.$el.submit();

            //setTimeout(() => {
            //  this.$router.push({ path: '/home' })
            //}, 2000);

          }, 500);

          this.closeCamera()
          
          //window.location.href = this.qrurl
          //window.open(code.data, '_blank')
          /*
          const url = new URL(code.data)
          const urlParams = new URLSearchParams(url.search);
          console.log("urlParams:"+urlParams);
          const cd = urlParams.get("cd");
          const type = urlParams.get("type");
          console.log("cd:"+cd);
          console.log("type:"+type);
          setTimeout(() => {
            this.$router.push({ path: 'home', query: { cd: cd , type: type } })
            this.closeCamera()
          }, 500);
          */
        }
      }, 500);
    },
    
    getCookies() {
      const CookieValue = this.$cookies.get('CookieValue');
      //console.log(JSON.stringify(CookieValue))
      if(CookieValue){

        this.email = CookieValue.email;
        this.username= CookieValue.username;
        this.access_token = CookieValue.tokens.access;
        this.refresh_token = CookieValue.tokens.refresh;

      }

    },
    setCookies() {
      const cookies = {
        email: this.email,
        username: this.username,
        tokens:{
          access: this.access_token,
          refresh: this.refresh_token
        }
      }

      this.$emit('set-cookies',cookies)
      /*
      this.email = value.email;
      this.username= value.username;
      this.access_token = value.tokens.access;
      this.refresh_token = value.tokens.refresh;

      const cookie = { 
        username: this.username, 
        refresh_token: this.refresh_token
      }
      */

    },
    checkLoggedIn() {
        this.email = '';
        this.username = '';
        this.access_token = '';
        this.refresh_token = '';

        const CookieValue = this.$cookies.get('CookieValue');
//console.log(JSON.stringify(CookieValue))
        if(CookieValue){

          this.email = CookieValue.email;
          this.username= CookieValue.username;
          this.access_token = CookieValue.tokens.access;
          this.refresh_token = CookieValue.tokens.refresh;

        }

        //this.$session.start();
        if (this.email=='') {
            router.push('/login', () => {});
        }
    },
    checkToken() {
        this.loading = true
        const refreshTokens = { refresh: this.refresh_token }
        
        axios.post('api/auth/token/refresh/', refreshTokens).then(res => {
            //console.log('res.data.access:'+JSON.stringify(res.data.access))

            if(res.data.access!=='' && res.data.access!==undefined){
              //console.log('ok:'+JSON.stringify(res.data))
  
              this.access_token = res.data.access

              this.setCookies()
              
              this.loading = false
            }else{
              console.log('on-logout:'+this.$route.path)
              this.onLogout()
            }
                //this.$cookies.set('email', res.data.email);
                //this.$cookies.set('username', res.data.username);

            // eslint-disable-next-line
        }).catch(e => {
          console.log(e.message);
            //this.logouted();

        })
        
        /*
        const results = await axios.post(baseUrl+'api/auth/token/refresh/', 
                  {
                  refresh: this.refresh_token
                  },
                  );
        const { data } = results;

        console.log('actions:'+data.results)
            */
    },
    onLogout(){
      this.$cookies.set('RoutePath', this.$route.path);
      this.$emit('on-logout')
    },
    logout() {
      this.$emit('check-logout',this.access_token)

        //this.$session.clear();

        /*
        axios.post('https://qrtimes.giverth.net/api/auth/logout/', 
          {
           refresh: this.refresh_token
          },
          {
           headers: { Authorization: 'JWT ' + this.access_token }
          },
          ).then(res => {
                //this.$cookies.set('email', res.data.email);
                //this.$cookies.set('username', res.data.username);
console.log(JSON.stringify(res.data))
                this.$cookies.remove('CookieValue');
                router.push("/login");
            // eslint-disable-next-line
            }).catch(e => {
              console.log(e.message);
                this.$cookies.remove('CookieValue');
                router.push("/login");

            })
*/
    }
  }
};
</script>

<style scoped lang="scss">
.treco {
  background: -moz-linear-gradient(65deg, #77a2ff, #b7e8ff); 
  background: -webkit-linear-gradient(65deg, #77a2ff, #b7e8ff); 
  background: linear-gradient(25deg, #77a2ff, #b7e8ff); 
}
</style>
