<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="300px">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      >
      <v-card 
        elevation="0"
        class="treco">
        <v-card-title>
          <v-row justify="center" no-gutters>
            <v-col></v-col>
            <v-col cols="auto">
            タイムカード
            </v-col>
            <v-col class="text-right">
              <v-icon 
                small 
                @click="closeDialog">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        
        <v-card-text>
          <v-row>
            <v-col
                cols="12">
                
                <p class="mb-0 font-weight-bold">姓</p>
                <v-text-field
                  dense
                  v-model="editedItem.last_name"
                  :counter="10"
                  maxlength="10"
                  :rules="rules.username"
                />
            </v-col>
            <v-col
                cols="12">
                
                <p class="mb-0 font-weight-bold">名</p>
                <v-text-field
                  dense
                  v-model="editedItem.first_name"
                  :counter="10"
                  maxlength="10"
                  :rules="rules.username"
                />
            </v-col>
          </v-row>
        </v-card-text>
        
        <v-card-actions>
          <v-btn
            color="grey lighten-2" 
            elevation="0" 
            @click="closeDialog"
          >
            キャンセル
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid" 
            color="primary" 
            elevation="0" 
            :loading="loading"
            @click="save"
          >
            保存
          </v-btn>
          
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
//import axios from 'axios';
//import Swal from 'sweetalert2';
//import router from "../../router";
//const baseUrl = document.domain //'https://qrtimes.giverth.net/'
//import jsQR from "jsqr"

export default {
  name: "TcardDialog",
  components: {
    //'qrcode-stream':QrcodeStream,
  },
  props:{
    rules: Object,
    updateItems: Object,
    trecoLatLng:{
      type:Object,
      required:true,
    },
    /*
    zoom:{
      type:Number,
      required:true,
    },
        */
    dialog:{
      type:Boolean,
      required:true
    },
    trdate:{
      type:String,
      required:true,
    },
    trtime:{
      type:String,
      required:true,
    },
  },
  data () {
    return {
      loading:false,
      valid: true,
      editedItem: this.updateItems,
    }
  },
  //watch: {
    
  //},
  mounted() {
    
    
  },
  methods: {
    save(){
        let update = {
                      'last_name': this.editedItem.last_name,
                      'first_name': this.editedItem.first_name,
                      'is_active': true,
                      'treco': this.editedItem.treco,
                    }
        
      this.$emit('save-tcard',update)
    },
    closeDialog() { 
      this.$emit('close-dialog-tcard')
    },
  }
 
};
</script>
