<template>
    <v-container grid-list-md>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm8 lg4 md5>
                <v-card class="login-card">
                    <v-card-title>
                    <span class="headline">パスワード再設定</span>
                    </v-card-title>

                    <v-spacer/>

                    <v-card-text>

                    <v-layout
                        row
                        fill-height
                        justify-center
                        align-center
                        v-if="loading"
                    >
                        <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                        />
                    </v-layout>


                    <v-form v-else ref="form" v-model="valid" lazy-validation>
                        <v-container>

                        <v-row>
                            <v-col
                                cols="12">
                                
                                <p class="mb-0 font-weight-bold">メールアドレス</p>
                                <email-text-field v-model="credentials.email" :rules="rules.email" />

                                <v-alert
                                    dense
                                    text
                                    :type="alertype"
                                    justify="center" 
                                    v-if="alert"
                                    >
                                    {{ alertmsg }}
                                </v-alert>
                            </v-col>
                        </v-row>

                        </v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                class="mt-4 pt-4">
                                <v-btn color="primary" elevation="0" block large @click="send">パスワード再設定URLを送信</v-btn>
                            </v-col>
                        </v-row>
                        

                    </v-form>


                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import EmailTextField from "../forms/EmailTextField.vue";
import axios from 'axios';
//import VueCookies from 'vue-cookies';
import Swal from 'sweetalert2';
import router from '../../router';

export default {
    name: 'Repassword',
    components: {
        'email-text-field': EmailTextField,
    },
    props: {
        rules: Object
      //logined: Boolean,
    },
    //data: () => ({
    data () {
        return {
            alert: false,
            alertype: '',
            alertmsg: '',
            credentials: {},
            valid:true,
            loading:false,
            cookievalue: '',
            
        }
    },
    //}),
    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.alert = false;
                this.alertype = 'error';
                this.alertmsg = 'メール送信に失敗しました。';
                this.credentials.redirect_url = 'https://qrtimes.giverth.net/%23/pwreset'
    //console.log('this.credentials:'+JSON.stringify(this.credentials))
                axios.post('https://qrtimes.giverth.net/api/auth/request-reset-email/', this.credentials).then(res => {
                    //this.$cookies.set('email', res.data.email);
                    //this.$cookies.set('username', res.data.username);
                    console.log(JSON.stringify(res.data))
                    if(res.data.errors!=='' && res.data.errors!==undefined){
                        console.log(JSON.stringify(res.data))
                        
                    }else{
                        this.alertype = 'success';
                        this.alertmsg = '上記メールアドレス宛に、パスワード再設定URLを送信しました。';
                    }
                    this.alert = true
                    this.loading = false;
                    //this.$emit('set-loggedIn',true)
                    
                    //router.push('/');
                // eslint-disable-next-line
                }).catch(e => {
                    this.loading = false;
                    Swal.fire({
                    type: 'warning',
                    title: 'Error',
                    text: 'エラー',
                    showConfirmButton:false,
                    showCloseButton:false,
                    timer:3000
                    })
                })
            }
        },
        rePassword() {
            router.push('/repassword', () => {});
        },
    }
}
</script>